import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/index',
      meta: {
        title: '住划算管理平台'
      },
      component: require('@/components/index').default
    }, {
      path: '/',
      component: require('@/components/product/index').default,
      children: [
        {
          path: '/',
          component: require('@/components/product/home').default
        },
        {
          path: '/homestay.html',
          component: require('@/components/product/homestay').default
        },
        {
          path: '/product.html',
          component: require('@/components/product/exhibition').default
        },
        {
          path: '/solution.html',
          component: require('@/components/product/solution').default
        },
        {
          path: '/solution_detailed.html',
          component: require('@/components/product/solution_detailed').default
        },
        , {
          path: "/ylt.html",
          name: "余乐通",
          component: require('@/components/product/introduce').default,
        },
        {
          path: '/solution_case.html',
          component: require('@/components/product/solution_case').default
        },
        {
          path: '/about_us.html',
          component: require('@/components/product/About_us').default
        }
      ]
    }
    , {
      path: "/duer/firstscreen1",
      name: "小度在酒店",
      component: require('@/components/duer/firstscreen1').default,
    }, {
      path: '/apply_cooperation',
      name: "申请云代理合作伙伴",
      component: require('@/components/apply/cooperation').default
    }, {
      path: '/apply_buy',
      name: "购买软件",
      component: require('@/components/apply/buy').default
    }, {
      path: '*',
      component: require('@/components/404').default
    }
  ]
})
