<template>
  <div class="MAbout_us">
    <!--  -->
    <div class="banner  df ai_c jc_c" style="background-image: url('/imgs/1605091540299.jpg');">
      <div class="content">
        <h2>把领先的软硬件技术，应用到业务场景中，使业务操作变得简单！</h2>
        <p>增加收益渠道和沟通效率，让管理者和客人，能享受科技带来的极致体验！</p>
      </div>
    </div>
    <!--  -->
    <div class="modular1 df ai_c jc_c">
      <div>
        <p>云店掌®、余乐通®、趣公寓®都是上海余乐计算机科技有限公司注册的商标，分别服务于综合连锁酒店和景区、本地化部署的酒店PMS系统、和云公寓Saas系统！我们2006年注册于上海财经大学科技园区，致力于酒店、景区、民宿信息化系统的研究和推广。历经十六年的沉淀和耕耘，我们为近万家行业客户上线了数字互联网信息化系统，这些客户和优秀的行业管理公司，为我们提供了丰富的产品意见，我们自身也主导和参股了部分酒店的筹建和经营，并一直坚持以人为本，我们加大技术研发，把更新更领先的软硬件技术方案，应用到我们的客户场景，使得业务操作变得简单，从而降低人员成本开支，让客人更方便了解商家的经营项目和理念，并提供自助交易平台，最终让管理者和客人，享受科技带来的极致体验感！</p>
        <p>自疫情以来，我们推出了住满多、入住通、壹码游、东家助理等小程序系列，让商家以更低成本，落地更先进的互联网方案，增加收益渠道，线上商城、周边商家、并把景区、酒店、餐饮、二销等场景，呈现在更聚合的小程序，让客人更方便了解和购买，系统结合后端的自动分账，自动为各个营业部门和周边商家实现分账报表，自动提现，不需要过多的学习和管理成本，快速落地！</p>
      </div>
    </div>
    <div class="modular2">
      <div class="title">全流程服务支持</div>
      <ul class="df jc_sb">
        <li class="fgrow">
          <div class="frame frame1" :style="'backgroundImage: url('+frame1+');'">
            <h6>开业前上线准备</h6>
            <p>· 硬件采买、操作系统安装指导</p>
            <p>· 局域网搭建测试</p>
            <p>· 系统安装、测试、初始化</p>
            <p>· 培训各岗位工作人员</p>
            <p>· 考试合格上岗</p>
            <p>· 模块系统验收</p>
          </div>
        </li>
        <li class="fgrow ">
          <div class="frame frame2" :style="'backgroundImage: url('+frame2+');'">
            <h6>开业中保驾护航</h6>
            <p>· 保驾系统正常运行</p>
            <p>· 保驾各岗位人员应用</p>
            <p>· 保驾管理部门对系统应用</p>
          </div>
        </li>
        <li class="fgrow ">
          <div class="frame frame3" :style="'backgroundImage: url('+frame3+');'">
            <h6>开业后贴心服务</h6>
            <p>· 故障维护</p>
            <p>· 定期上门</p>
            <p>· 人员培训</p>
            <p>· 管理咨询</p>
          </div>
        </li>
      </ul>
    </div>
    <!--  -->
    <div class="modular3">
      <ul class="df ai_c jc_sb">
        <li class="fgrow df fd_c ai_c jc_c">
          <h6>使命</h6>
          <div class="describe df ai_c jc_c" style="background:#F9853B;">为酒店民宿提供最优秀的互联网解决方案</div>
        </li>
        <li class="fgrow df fd_c ai_c jc_c">
          <h6>愿景</h6>
          <div class="describe df ai_c jc_c" style="background:#02B2CB;">成为行业持续前进的推动者</div>
        </li>
        <li class="fgrow df fd_c ai_c jc_c">
          <h6>价值观</h6>
          <div class="describe df ai_c jc_c" style="background:#36588B;">以人为本，懂管理，会赚钱</div>
        </li>
      </ul>
    </div>
    <!--  -->
    <div class="modular4">
      <div class="title">联系我们</div>
      <ul class="df jc_sb">
        <li class="fgrow">
          <div class="frame frame1">
            <h6>联系我们</h6>
            <p>上海市杨浦区国定路335号8010-16室</p>
            <h2>400-6710-880</h2>
            <h2>180-190-39339 (微信同号)</h2>
          </div>
        </li>
      </ul>
    </div>

    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      frame1: "/imgs/time.png",
      frame2: "/imgs/protect.png",
      frame3: "/imgs/maintenance.png",
    };
  },
};
</script>

<style lang="less">
@import "~@/assets/less/about_us.less";
</style>