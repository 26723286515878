<template>
  <div class="Mindex">
    <div class="left fauto" style="width:200px;">
      <div class="main_nav he100b df fd_c jc_sb">
        <div class="topnr df fd_c ai_c jc_c">
          <h2>余乐通</h2>
          <img class="logo" src="/ylt/logo.png" alt="" />
          <p style="margin-top:10px;">智能化酒店管理系统</p>
        </div>
        <div class="bottomnr df fd_c ai_c">
          <p>合作伙伴</p>
          <p>
            全方位管理的余乐通酒店系统，从2006年起我们专注于酒店行业，简单快速，方便易用！扫码免费获取！

          </p>
          <img class="QRCode" src="/ylt/wx_yundz.jpg" alt="二维码" />
        </div>
      </div>
    </div>
    <div class="right">
      <!--  -->
      <div class="modular1">
        <div class="slidetxt" style="margin-top:100px;width:auto;">
          <span class="slidetitle">余乐通-本地化部署，安全、方便，打造细节丰富的酒店智能化管理系统<br />
            Localized Deployment hotel management system</span>
        </div>
        <img src="/img/dakehu.jpg" alt="" />
      </div>
      <!--  -->
      <div class="modular2 df jc_sb ai_c" style="height:0;">
        <div>
          <span class="login">登录/</span><span class="login">注册</span>
        </div>
        <div class="df">
          <img src="/img/phone.png" alt="" class="loginbj" />
          <span class="phone">免费咨询热线：400-6710-880  <span style="padding-left:20px;">180-190-39339 (微信同号)</span></span>
        </div>
      </div>
      <!--  -->
      <div class="modular3 df ai_c jc_sa">
        <div class="content" style="width:33.33%">
          <h3>独立部署的酒店管理系统</h3>
          <p>
            系统化的酒店管理系统有丰富的功能分支，应用非常宽泛，本地化部署，让前台操作员专注于快速操作，帮助客人办理业务，支持OTA、身份证、打印机、门锁、公安上传、智能客控等等丰富专业的功能
          </p>
          <a class="button" style="margin-top:20px;">立即使用</a>
        </div>
        <div class="content" style="width:33.33%">
          <h3>餐饮系统、洗浴足疗、会员一卡通</h3>
          <p>
            多场景综合的管理系统，各系统之间数据独立，且互相API接口互通，安全可靠，满足更多数字化酒店的需求
          </p>
          <a class="button" style="margin-top:20px;" target="_blank" href='http://pmsgroup.9dpms.com/'>立即使用</a>
        </div>
        <div class="content" style="border:none;width:33.33%;">
          <h3>随时随地微信查看酒店数据</h3>
          <p>
            余乐通管家，是余乐通系列产品的微信端，为酒店经营者提供随时随地查看酒店运营情况的统计图表，及时了解运营情况，定制策略
          </p>
          <a class="button" style="margin-top:20px;">立即使用</a>
        </div>
      </div>
      <div class="modular4">
        <div class="nr">
          <p>
            云店掌-自2006年创立开始，余乐通就一直致力于酒店行业的管理系统探索和研发，我们定位中档酒店智能化管理系统，十四年来我们接触到近万家酒店、酒店管理公司，我们非常感谢这些企业给与我们的大力支持和建议！这些宝贵意见成为余乐通产品的一部分，让我们一系列产品极具生命力！
          </p>
        </div>
      </div>
      <!--  -->
      <div class="modular5" :style="{ backgroundImage: 'url(' + img + ')', backgroundSize: 'cover' }">
        <div class="title">
          <h2>我们的优势</h2>
          <h3>OUR ADVANTAGE</h3>
        </div>
        <div class="content">
          <div class="li">
            <h3><span>全面</span> comprehensive</h3>
            <p>
              不仅仅是酒店管理系统，还集合了智慧客控、OTA对接、联合营销、微信小程序、支付等等
            </p>
          </div>
          <div class="li">
            <h3><span>经验</span> accurate</h3>
            <p>十七年来我们接触到近万家酒店、酒店管理公司，我们非常感谢这些企业给与我们的大力支持和建议！</p>
          </div>
          <div class="li">
            <h3><span>简单安全</span> timely</h3>
            <p>本地化部署、化繁为简，简而不单的设计理念，余乐通成为市面上最好操作的PMS系统</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="modular6">
        <div class="title">
          <h2>四大核心优势</h2>
          <h3>4 Core advantages</h3>
        </div>
        <div class="tabs">
          <div class="nav df ai_c jc_sb">
            <div class="li fgrow df ai_c jc_c" :class="[tabActive == 1 ? 'active' : '']" @click="tabActive = 1">快速简单</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive == 2 ? 'active' : '']" @click="tabActive = 2">品牌分析</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive == 3 ? 'active' : '']" @click="tabActive = 3">经验累积</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive == 4 ? 'active' : '']" @click="tabActive = 4">营销分析</div>
          </div>
          <div class="content" v-if="tabActive == 1">
            <div class="df fw_w">
              <div class='acol12' style="padding:10px;box-sizing:border-box;">1、余乐通能实现一键开房，一键退房，一键分析等，对常用的功能自动处理，在服务的高峰期，仍然得心应手</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">2、精细化管理模块，数千家酒店的智慧结晶，内容更加丰富全面，综合的报表系统</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">3、十七年的技术沉淀，功能和数据的综合统一，为酒店发展支撑</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">4、Windows、微信、接口，我们在酒店应用的很多层面提供子系统，全方位营销、管理</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">5、智能门锁、智慧客控、自助开房机、微信支付宝收款、结账自动退还押金、小程序OTA对接，数据互联</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">6、功能便捷，自动化集成度高，前台有更多时间服务客人</div>
            </div>
          </div>
          <div class="content" v-if="tabActive == 2">
            <div class="df fw_w">
              <div class='acol12' style="padding:10px;box-sizing:border-box;">1、CS构架，本地化部署，打造安全、极致快速的操作体验</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">2、超过300种功能:功能模块包含前厅业务、房务中心、财务报表、进销存、业绩考核、会员、协议、营销管理、操作员、人事工资等等</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">3、深入细节:每个功能都做了深入调查和分析，让酒店能用上，用好</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">4、颜色强调和自适应:余乐通产品有丰富的颜色样式，警示操作的重要性。所有表格都能根据酒店的需求配置显示或者不显示字段</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">5、APP的流程控制:不同于网页展示，用户容易关闭网页导致数据检查的不一致，APP通过模式窗体来控制关闭的层次，确保流程更加可控</div>
            </div>
          </div>
          <div class="content" v-if="tabActive == 3">
            <div class="df fw_w">
              <div class='acol12' style="padding:10px;box-sizing:border-box;">1、17年6000+家纯酒店客户为我们提供宝贵经验</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">2、为甲方服务的理念，自动化的房价策略，财务报表和权限，我们在极致功能下权衡的是甲方的利益</div>
              <div class='acol12' style="padding:10px;box-sizing:border-box;">3、从酒店的内部管理、成本分析、外部的营销工具、管理APP、OTA我们均有涉及</div>
            </div>
          </div>

          <div class="content" v-if="tabActive == 4">
            <div class="df fw_w">
              <div style="padding:10px;box-sizing:border-box;">1、微信有强大的粘性用户群体，且不需要客人下载APP直接在微信就可以使用！余乐通基于微信不仅仅打造了管理酒店的余乐通管家，还有非住不可订房平台、卖品服务、周边商家联盟、智慧房务等产品，致力于打造酒店管理(ToB)，智慧营销(ToC)生态联合系统，为酒店提供全方位的管理，营销工具</div>
              <div style="padding:10px;box-sizing:border-box;">2、余乐通专门为酒店定制了小程序，用于销售客房、优惠券、商城和会员直连，全方位组合营销，为酒店做好生意/改名）及广告方法一目了然；</div>
              <div style="padding:10px;box-sizing:border-box;">3、会员直连、连锁共享、协议客户通用、统计查询等等，我们的云端数据为酒店管理层决策分析，做了深入数据统计，为酒店进一步扩大市场提供数据基础</div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="modular7" :style="{ backgroundImage: 'url(' + imgbj + ')', backgroundSize: 'cover' }">
        <div class="title">
          <h2>我们的客户</h2>
          <h3>OUR CLIENTS</h3>
          <p>余乐通系列产品从创立初期就定位中端酒店客户群体后来衍生对精品酒店、主题创意酒店、民宿等场景的支持，目前超过30,000家酒店、餐饮、洗浴、足疗等行业客户，使用了余乐通产品服务</p>
        </div>
        <div class="content df fw_w">
          <div class="ant-col" v-for="(item, index) in list" :key="index">
            <div class="img df ai_c jc_c">
              <img :src="item" alt=""  />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- <div class="modular8">
        <p>上海余乐计算机科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备15047937号-1</a></p>
        <p style="margin-top:10px;text-align: left;">自2006年创立开始，余乐通就一直致力于酒店行业的管理系统探索和研发，我们定位中档酒店智能化管理系统，十四年来我们接触到近万家酒店、酒店管理公司，我们非常感谢这些企业给与我们的大力支持和建议！这些宝贵意见成为余乐通产品的一部分，让我们一系列产品极具生命力！</p>
      </div> -->
      <!--  -->
    </div>
    <!--  -->
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      img: "/img/ysbj2.png",
      imgbj: "/img/mkbj.png",
      tabActive: 1,
      list: [],
    };
  },
  created() {
    let list = [];
    for(let i = 1;i<34;i++){
      list.push("/ylt_logo/logo"+i+".jpg")
    }
    console.log(list)
    this.list = list;
  },
};
</script>
<style lang="less">
@import "~@/assets/less/index.less";
</style>