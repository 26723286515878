<template>
  <div class="Mexhibition" ref="Mexhibition">
    <div class="banner  df ai_c jc_c" style="background-image: url('/imgs/exhibition/28801140.jpg');">
      <div class="content">
        <h2>懂管理，会运营，为商家提供能赚钱的互联网系统平台</h2>
        <p>懂管理，会运营，为商家提供能赚钱的互联网系统平台</p>
      </div>
    </div>
    <!-- left nav -->
    <div class="contentScroll">
      <!--  -->
      <div class="left_nav" :class="[leftnav_state?'fixed':'']">
        <!-- <h4>导航1</h4> -->
        <ul>
          <li class="df ai_c" @click="scroll_position(item.top)" v-for="(item,index) in left_nav" :key="index"><span class="iconfont">{{item.icon}}</span>{{item.name}}</li>
        </ul>
      </div>
      <!--  -->
      <div class="right_content">
        <!--  -->
        <div class="modular1" ref="modular1">
          <div class="img">
            <img src="/imgs/exhibition/63759a1988477.jpeg" alt="">
          </div>
          <div class="title">
            <h2>云店掌Saas构架 综合数字化互联网酒店管理平台</h2>
            <h5>适用于酒店、餐饮、民宿、景区、游乐场等场景</h5>
          </div>

          <div class="introduce">
            云店掌是一套综合体系，包含云店掌PMS、入住通运营小程序、东家助理B端分布式业务协同办公等多个产品终端，云店掌不仅仅支持Windows，还能支持安卓、华为鸿蒙、也能在微信体系中运行良好，能直接在信小程序中实现押金、退房、续住、账单等等常用操作云店掌还能与各大智慧平台互联，比如小度在酒店等等，实现智慧客房
          </div>
          <div class="img">
            <img src="/imgs/exhibition/w9lk56VyILIp.png" alt="">
          </div>

          <ul class="list df jc_sb">
            <li>
              <div class="xbt">
                <h3>
                  <span class="iconfont">&#xe600;</span>
                  酒店客房
                  <span class="iconfont">&#xe600;</span>
                </h3>
                <h5>涵盖上百种酒店业务</h5>
              </div>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>
                  如前厅业务入住、预订、换房、消费、外卖品、结算等等
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  账单冻结，一键开房、退房，极简操作模式
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  自动夜审，自动房价
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  房价漏洞监管
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  微信支付宝对接
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  上下班、交接款
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  业绩考核，订单、服务溯源
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  维修房
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  订单搜索和报表系统
                </span>
              </p>
            </li>
            <li>
              <div class="xbt">
                <h3>
                  <span class="iconfont">&#xe600;</span>
                  茶餐厅/棋牌
                  <span class="iconfont">&#xe600;</span>
                </h3>
                <h5>厨打+扫码点餐</h5>
              </div>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  支持分单厨房打印
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  客人扫码微信点餐
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  服务员点单
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  电脑点餐、点餐机支持，口味喜好、线上支付，前台买单，单据打印
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  多种方式结算
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  优惠券支持
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  线上会员支持
                </span>
              </p>
            </li>
            <li>
              <div class="xbt">
                <h3>
                  <span class="iconfont">&#xe600;</span>
                  运营/智慧酒店
                  <span class="iconfont">&#xe600;</span>
                </h3>
                <h5>入住通运营</h5>
              </div>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  线上商城、管家服务、开票、连Wifi
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  早餐券，线上会员
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  充值活动、积分活动，赠送管理
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  线上订房、抢购秒杀
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>

                  房间打扫、周边商家异业联盟
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>
                  所有订单：客人扫房间码点单->服务员接单->送达商品/服务->评价->业绩奖励
                </span>
              </p>
              <p class="df">
                <span class="iconfont">&#xe610;</span>
                <span>
                  小度在酒店，百度智慧酒店
                </span>
              </p>
            </li>
          </ul>

        </div>
        <!--  -->
        <div class="modular11" ref="wxApplet_rzt">
          <div class="title df ai_c jc_sb">
            <h2>快速简单</h2>
            <h5>Fast and simple</h5>
          </div>
          <div class="content df">
            <div class="list1 df jc_sb">
              <div class="li">
                <div class="img fauto" style="background-image: url('/imgs/exhibition/221122111824.jpg');"></div>
                <div>
                  <h3>便捷的单键操作</h3>
                  <p>云店掌能实现一键开房，一键退房，一键分析等，对常用的功能自动处理，在服务的高峰期，仍然得心应手</p>
                </div>
              </div>
              <div class="li">
                <div class="img fauto" style="background-image: url('/imgs/exhibition/1177f56677.jpg');"></div>
                <div>
                  <h3>更智能的对接</h3>
                  <p>智能门锁、智慧客控、自助开房机、微信支付宝收款、结账自动退还押金、小程序OTA对接，数据互联</p>
                </div>
              </div>
            </div>
            <div class="list2">
              <div class="li">
                <h3 class="df ai_c"><span class="icon df ai_c jc_c">1</span><span>快速</span></h3>
                <p>功能便捷，自动化集成度高，前台有更多时间服务客人</p>
              </div>
              <div class="li">
                <h3 class="df ai_c"><span class="icon df ai_c jc_c">2</span><span>丰富</span></h3>
                <p>十五年的技术沉淀，功能和数据的综合统一，为酒店发展支撑</p>
              </div>
              <div class="li">
                <h3 class="df ai_c"><span class="icon df ai_c jc_c">3</span><span>全面</span></h3>
                <p>精细化管理模块，数千家酒店的智慧结晶，内容更加丰富全面，综合的报表系统</p>
              </div>
              <div class="li">
                <h3 class="df ai_c"><span class="icon df ai_c jc_c">4</span><span>平台</span></h3>
                <p>Windows、微信、接口，我们在酒店应用的很多层面提供子系统，全方位营销、管理</p>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="modular12">
          <div class="title df ai_c jc_sb">
            <h2>未来酒店该有的样子</h2>
            <h5>What a hotel should look like in the future</h5>
          </div>
          <div class="list df ">
            <div class="li df ai_c jc_c">
              <p>能耗、智慧、管理: 一目了然，自动关闭或降低无效能耗</p>
            </div>
            <div class="li df ai_c jc_c">
              <p>一呼百应，自然语音控制的综合平台，您可以在房间里跟智能音箱交互，叫服务、退房、叫商品等等。还支持懒人模式，多种情景调光模式</p>
            </div>
            <div class="li df ai_c jc_c">
              <p>无人化: 人脸识别入住，对接身份上传系统</p>
            </div>
          </div>
          <div class="list df">
            <div class="li df ai_c jc_c">
              <p>多联盟商家整合资源，周边商家，综合营销。你还可以把所有您触及的喜欢物品，平台下单，我们给你送回家</p>
            </div>
            <div class="li df ai_c jc_c">
              <p>自动化: 送货机器人，客房房间下单，机器人自动送货至客房，客人扫码取货</p>
            </div>
            <div class="li df ai_c jc_c">
              <p>用户粘性高，酒店开支少，联合营销收益上升</p>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="modular13">
          <div class="title df  ai_c jc_sb">
            <h2>客户经验积累</h2>
            <h5>Customer experience accumulation</h5>
          </div>
          <div class="content df ai_c">
            <div class="img fauto">
              <img src="/imgs/exhibition/221122112628.jpg" alt="">
            </div>
            <div class="right">
              <h3>15年7000+家商家为我们提供宝贵经验</h3>
              <p>云店掌系列产品发展至今，除了我们15年一直专注行业的研究之外最重要的是我们的客户和行业管理公司给我们提供大量的意见和建议帮助我们对行业的了解认知更上一层楼!</p>
              <p>云店掌的目标就是把软件解决方案渗透到行业业务中去，简化和规范化业务操作，让工作变得更加简单，为客户的发展提供持续性的产品支持!</p>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="modular14">
          <div class="title df ai_c jc_sb">
            <h2>产品的核心理念</h2>
            <h5>Core concept of products</h5>
          </div>
          <div class="list df fw_w">
            <div class="fgrow li df ai_c">
              <div class="left df fd_c ai_c jc_c">
                <h5>01</h5>
                <h3>简单</h3>
              </div>
              <div class="right">
                <h2>让工作变得更简单</h2>
                <p>细致打磨每个功能，能自动化的步骤我们尽量减少人员干预，让操作极致简单</p>
              </div>
            </div>
            <div class="fgrow li df ai_c">
              <div class="left df fd_c ai_c jc_c">
                <h5>02</h5>
                <h3>专业</h3>
              </div>
              <div class="right">
                <h2>专注酒店客房领域</h2>
                <p>15年我们一直致力于酒店智能化领域的系统研究和开发专注成就今天的云店掌</p>
              </div>
            </div>
            <div class="fgrow li df ai_c">
              <div class="left df fd_c ai_c jc_c">
                <h5>03</h5>
                <h3>服务</h3>
              </div>
              <div class="right">
                <h2>为甲方服务</h2>
                <p>自动化的房价策略，财务报表和权限，我们在极致功能下权衡的是甲方的利益</p>
              </div>
            </div>
            <div class="fgrow li df ai_c">
              <div class="left df fd_c ai_c jc_c">
                <h5>04</h5>
                <h3>全面</h3>
              </div>
              <div class="right">
                <h2>全方位对应酒店业务</h2>
                <P>从酒店的内部管理、成本分析、外部的营销工具、管理APP、OTA我们均有涉及</P>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div style="height:50px"></div>
        <!--  -->
        <div class="modular2" ref="modular2">
          <div class="img">
            <img src="/imgs/exhibition/EoDnJH1j3p.jpeg" alt="">
          </div>
          <div class="title">
            <h2>云店掌PMS 手机上的PMS</h2>
            <p>东家助理</p>
          </div>
          <!-- <div class="df ai_c jc_c">
            <svg :width='500' :height='500' @mousemove='listener($event)'>
              <a v-for='(tag,index) in tags' :key="index">
                <text :x='tag.x' :y='tag.y' :font-size='20 * (600/(600-tag.z))' :fill-opacity='((400+tag.z)/600)'>{{tag.text}}</text>
              </a>
            </svg>
          </div> -->
          <div class="introduce">
            酒店授权的员工，可以直接登录微信看到房态、修改房态、打扫房间，可以为客人续住、收押金、加消费，可以为客人退房客人办理业务不需要去前台，直接找工作人员进行办理即可，极大减轻前台的工作压力，也提升客人的体验感
          </div>
          <div class="img">
            <img src="/imgs/exhibition/688d51WH5c3P.jpeg" alt="">
          </div>
          <div class="introduce">
            酒店授权的管理层，根据权限，可以办理酒店业务，也可以查看多大三十多项的统计数据报表，及时了解酒店的经营情况，了解实时房态情况，了解客源、占比、入住率、Revpar等等重要的数据指标！
          </div>

        </div>
        <!--  -->
        <div style="height:50px"></div>
        <!--  -->
        <div class="modular3" ref="modular3">
          <div class="title">
            <h2>云店掌PMS 智慧客房</h2>
            <p>百度--小度在酒店</p>
          </div>
          <div class="img">
            <img src="/imgs/exhibition/221122143920.jpg" alt="">
          </div>
          <div class="title1">
            <h2>越来越多的酒店在拥抱小度</h2>
          </div>
          <div class="content2 df">
              <div class="list df fw_w">
                <div class="li">
                  <h3>35万 <small>+</small></h3>
                  <p>累计客房数</p>
                </div>
                <div class="li">
                  <h3>30000<small>+</small></h3>
                  <p>月均客房增长</p>
                </div>
                <div class="li">
                  <h3>10000<small>+</small></h3>
                  <p>覆盖酒店</p>
                </div>
                <div class="li">
                  <h3>1亿<small>+</small></h3>
                  <p>累积服务人次</p>
                </div>
                <div class="li">
                  <h3>400<small>+</small></h3>
                  <p>覆盖城市</p>
                </div>
                <div class="li">
                  <h3>360万<small>+</small></h3>
                  <p>日均唤醒次数</p>
                </div>
              </div>
              <div class="img">
                <img src="/imgs/exhibition/221122145033.jpg" alt="">
              </div>
          </div>
          <div class="introduce">
            <p class="df ai_c jc_c">IDC预测，2024年，将有60%的全球豪华酒店客房内将标配智能语音助手，而中国速度呢？</p>
          </div>

          <div class="title1">
            <h2>广受住客欢迎的小度智能</h2>
          </div>
          <div class="content3 df jc_sb">
            <div class="left df fw_w">
              <div class="li df fd_c ai_c jc_c">
                <div class="item df fd_c ai_c jc_c">
                  <h3 class="">53.64%</h3>
                  <p>客房控制</p>
                </div>
              </div>
              <div class="li df fd_c ai_c jc_c">
                <div class="item df fd_c ai_c jc_c">
                  <h3 class="">19.11%</h3>
                  <p>音乐</p>
                </div>
              </div>
              <div class="li df fd_c ai_c jc_c">
                <div class="item df fd_c ai_c jc_c">
                  <h3 class="">10.16%</h3>
                  <p>客房服务</p>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="li">
                <h3>360万次+/天</h3>
                <h5>酒店版小度日均唤醒次数</h5>
              </div>
              <div class="li">
                <h3>133次+/天</h3>
                <h5>平均每天节省通话次数（4000次/30天）</h5>
              </div>
              <div class="li">
                <h3>0.83人力/天</h3>
                <h5>每次通话3分钟，节省400分钟/天，即6.7小时/天</h5>
              </div>
            </div>
          </div>
          <div class="title1">
            <h2>小度酒店行业整体解决方案</h2>
            <p>小度全链打通酒店生态，为酒店智能化升级转型保驾护航</p>
          </div>
          <div class="img">
            <img src="/imgs/exhibition/221122154134.jpg" alt="">
          </div>
          <div class="title1">
            <h2>小度+客需&配送机器人，“全域智能”全面提升运营效率</h2>
            <p>百度推出全新配送机器人，助力酒店驱动行业智慧场景革命，构建真正意义上的无人零售解决方案</p>
          </div>
          <div class="content4 df fw_w">
            <div class="li">
              <img src="/imgs/exhibition/xdps1.jpg" alt="">
            </div>
            <div class="li">
              <img src="/imgs/exhibition/xdps2.jpg" alt="">
            </div>
            <div class="li">
              <img src="/imgs/exhibition/xdps3.jpg" alt="">
            </div>
            <div class="li">
              <img src="/imgs/exhibition/xdps4.jpg" alt="">
            </div>
          </div>
          <div class="img">
            <img src="/imgs/exhibition/xF3yQjufNc5.jpg" alt="">
          </div>
          <div class="introduce">
            云店掌对接了百度的智慧酒店项目（小度在酒店），为酒店打造智慧酒店，互联网酒店，提升酒店的核心竞争优势！
          </div>

        </div>
        <!--  -->
        <div class="modular13" ref="shutter">
          <div class="title df  ai_c jc_sb" style="background:#008000;">
            <h2>壹码游</h2>
            <h5>基于游客视角，统一旅游资源的互联网运营销售平台</h5>
          </div>
          <div class="content df ai_c">
            <div class="img fauto">
              <img src="/imgs/exhibition/20231018175618.png" alt="">
            </div>
            <div class="right">
              <h3>文旅景区综合业态、二销场景的支持</h3>
              <p>游客通过扫码进入壹码游小程序，可以在任何地方，浏览景区所有商家图片、评价内容，商家的商品、价格、优势、描述和图片，并支持下单购买商品、门票、成为线上会员等功能，并以游客视角为核心，对景区体验写下最真实的反馈，对运营方的数据采集、溯源、跟踪、并解决。壹码游平台为运营方提升旅游品质、打造可持续化发展提供多维度数据支持。</p>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="shutter">
          <div class="title">
            <h2>智慧景区、民宿数字化解决方案</h2>
            <p>壹码游-运营销售平台</p>
          </div>
          <ul class="df jc_sb">
            <li class="fauto" v-for="(item,index) in shutter_data" @click="shutter_active=index" :key="index" :class="[shutter_active==index?'display':'']" :style="{backgroundImage:'url('+item.bjurl+')',backgroundColor:item.bjcolor}">
              <h3>{{item.name}}</h3>
            </li>
          </ul>
          <div class="introduce">
            <div class="list df fw_w">
              <div class="li df ai_c" v-for="(item,index) in shutter_data[shutter_active].list" :key="index" :class="[shutter_active==index?'display':'']">
                <span class="iconfont">&#xe600;</span>{{item}}
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="ChargingBox" ref="ChargingBox">
          <div class="img">
            <img src="/imgs/exhibition/cdhz.jpg" alt="">
          </div>
          <div class="introduce">
          </div>
        </div>
        <!--  -->
      </div>
    </div>
    <!--  -->
  </div>
</template>
<script>
export default {
  // metaInfo: {
  //   title: "",
  //   meta: [
  //     {
  //       name: "",
  //       content: "",
  //     },
  //   ],
  //   link: [
  //     {
  //       rel: "asstes",
  //       href: "https://assets-cdn.github.com/",
  //     },
  //   ],
  // },
  data() {
    return {
      leftnav_state: false,
      left_nav: [
        {
          icon: "",
          name: "云店掌Saas系统",
          class: "modular1",
          top: 0,
        },
        {
          icon: "",
          name: "入住通",
          class: "wxApplet_rzt",
          top: 1000,
        },
        {
          icon: "",
          name: "壹码游",
          class: "shutter",
          top: 4000,
        },
        {
          icon: "",
          name: "东家助理",
          class: "modular2",
          top: 0,
        },
        {
          icon: "",
          name: "小度在酒店",
          class: "modular3",
          top: 0,
        },
        {
          icon: "",
          name: "共享充电",
          class: "ChargingBox",
          top: 0,
        },
      ],
      shutter_data: [
        {
          bjurl: "/imgs/exhibition/muwu.jpg",
          bjcolor: "#379392",
          name: "客房/木屋",
          list: [
            "住宿是云店掌核心的业务",
            "支持智能门锁远程开锁、锁定自动计算房价，多种计费模式",
            "支持微信支付宝担保交易",
            "实时房态随处可以查看",
            "可以联合微信小程序给客人续期、补交押金、销券等等移动业务",
          ],
        },
        {
          bjurl: "/imgs/homestay/modular5bj.jpg",
          bjcolor: "#4FB0C6",
          name: "帐篷/天幕",
          list: [
            "支持场地租赁模式",
            "支持按次购买",
            "支持计时收费，可以租赁帐篷自带帐篷等不同收费模式",
            "支持线上购券，线下核销",
          ],
        },
        {
          bjurl: "/imgs/homestay/mk62.png",
          bjcolor: "#4F86C6",
          name: "房车/KTV",
          list: [
            "支持房车智能钥匙管理",
            "支持按次、计时等收费模式",
            "房车内支持扫码购买商品服务员送达",
            "计费模式也支持KTV计费等场景",
          ],
        },
        {
          bjurl: "/imgs/exhibition/dqsk.jpg",
          bjcolor: "#d9e1e8",
          name: "钓鱼/烧烤",
          list: [
            "线上购买钓鱼券，分时分类计价，支持押金模式（担保交易）",
            "超时后自动计费",
            "结算时押金扣除，余款自动原路返回",
            "支持钓鱼点购买渔具、商品",
          ],
        },
        {
          bjurl: "/imgs/exhibition/rqq.jpg",
          bjcolor: "#f15c5c",
          name: "游泳/滑翔伞/热气球",
          list: [
            "支持游泳、露天游泳场地",
            "支持线上购买券",
            "按次、计时计费都可以支持",
            "也支持押金模式",
            "游泳场所可以用手机售卖游泳衣、游泳圈等本场所商品",
            "也支持儿童乐园、水上乐园等场景",
          ],
        },
      ],
      shutter_active: 1,
      //
      tags: [],
      speedX: Math.PI / 360,
      speedY: Math.PI / 360,
    };
  },
  created() {
    this.ins_tags();
    let time = setInterval((res) => {
      clearInterval(time);
      this.get_leftnavTop();
    }, 500);
  },
  mounted() {
    window.addEventListener("scroll", this.Scroll); // 监听页面滚动

    setInterval(() => {
      this.rotateX(this.speedX);
      this.rotateY(this.speedY);
    }, 17);
  },
  computed: {
    CX() {
      return 500 / 2;
    },
    CY() {
      return 500 / 2;
    },
  },
  methods: {
    get_leftnavTop() {
      let nav = this.left_nav;
      for (var i in nav) {
        if (this.$refs[nav[i].class].offsetTop) {
          let offsetTop = this.$refs[nav[i].class].offsetTop + 450;
          nav[i].top = offsetTop;
        }
      }
      this.left_nav = nav;
      console.log(nav);
    },
    ins_tags() {
      let tagsNum = 20;
      let RADIUS = 200;
      let tags = [];

      for (let i = 0; i < tagsNum; i++) {
        let tag = {};
        let k = -1 + (2 * (i + 1) - 1) / tagsNum;
        let a = Math.acos(k);
        let b = a * Math.sqrt(tagsNum * Math.PI);
        tag.text = i + "tag";
        tag.x = this.CX + RADIUS * Math.sin(a) * Math.cos(b);
        tag.y = this.CY + RADIUS * Math.sin(a) * Math.sin(b);
        tag.z = RADIUS * Math.cos(a);
        tags.push(tag);
      }
      this.tags = tags;
    },
    rotateX(angleX) {
      var cos = Math.cos(angleX);
      var sin = Math.sin(angleX);
      for (let tag of this.tags) {
        var y1 = (tag.y - this.CY) * cos - tag.z * sin + this.CY;
        var z1 = tag.z * cos + (tag.y - this.CY) * sin;
        tag.y = y1;
        tag.z = z1;
      }
    },
    rotateY(angleY) {
      var cos = Math.cos(angleY);
      var sin = Math.sin(angleY);
      for (let tag of this.tags) {
        var x1 = (tag.x - this.CX) * cos - tag.z * sin + this.CX;
        var z1 = tag.z * cos + (tag.x - this.CX) * sin;
        tag.x = x1;
        tag.z = z1;
      }
    },
    listener(event) {
      let RADIUS = 200;
      //响应鼠标移动
      var x = event.clientX - this.CX;
      var y = event.clientY - this.CY;
      this.speedX =
        x * 0.0001 > 0
          ? Math.min(RADIUS * 0.00002, x * 0.0001)
          : Math.max(-RADIUS * 0.00002, x * 0.0001);
      this.speedY =
        y * 0.0001 > 0
          ? Math.min(RADIUS * 0.00002, y * 0.0001)
          : Math.max(-RADIUS * 0.00002, y * 0.0001);
    },

    Scroll(e) {
      let scroll_top = document.documentElement.scrollTop;
      if (scroll_top > 470) {
        this.leftnav_state = true;
      } else {
        this.leftnav_state = false;
      }
    },
    scroll_position(top) {
      console.log(top)
      document.documentElement.scrollTop = top - 70;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.Scroll);
  },
};
</script>

<style lang="less">
@import "~@/assets/less/exhibition.less";
</style>