<template>
  <div class="Msolution_case">
    <!--  -->
    <div class="banner df ai_c jc_c" style="background-image: url('/imgs/1605091540298.jpg');">
      <h2 class="content df ai_c jc_c">{{name}}</h2>
    </div>
    <!--  -->
    <div class="modular_content">
      <!--  -->
      <p><strong>上线云店掌收银POS饮食通版、部分门店上线自助大屏点餐、CRM、HBI数据分析系统、微信小程序、积分商城、自营外卖、排队等位、巡店系统、后厨出品管控KDS系统、订多多预订管理系统。利用互联网工具全面提升“老字号”品牌创新发展。</strong></p>
      <p></p>

      <!--  -->

    </div>

    <!--  -->
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      name: "",
      navlist: [],
    };
  },
  created() {
    this.name = this.$route.query.name;
  },
  mounted() {},
  methods: {},
  beforeDestroy() {},
};
</script>
  
  <style lang="less">
@import "~@/assets/less/solution_case.less";
</style>