<template>
  <div>
    <div class="Mfirstscreen1">
      <!-- 900x590 -->
      <div class="lnr">
        <div class="QRCode" v-if="comkey">
          <img :src="QRCode" v-if="QRCode" mode="" />
        </div>
        <div class="QRCode" v-else>
          <img src="/img/wx_yundz.jpg" mode="" />
        </div>
        <div class="banner">
          <img src="https://static-1252855848.cos.ap-shanghai.myqcloud.com/duer/banner1.jpg" mode="" />
        </div>
      </div>
      <div class="rnr">
        <div class="liitem">
          <div class="textnr">
            <div class="title">
              卖品商城
            </div>
            <div class="subtitle">
              扫码下单，送至客房，懂你所需
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />店内商品和周边商家
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />全天24小时营业
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />由客房管家送至房间
            </div>
          </div>
          <img class="img" src="https://static-1252855848.cos.ap-shanghai.myqcloud.com/duer/shoppingmall.jpg" mode="" />
        </div>
        <div class="liitem">
          <div class="textnr">
            <div class="title">
              会员直连
            </div>
            <div class="subtitle">
              轻松消费，尊享延住，更多优惠
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />随时查看消费明细
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />分享二维码获得收益
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />更低的房价更多优惠券
            </div>
          </div>
          <img class="img" src="https://static-1252855848.cos.ap-shanghai.myqcloud.com/duer/member.jpg" mode="" />
        </div>
        <div class="liitem">
          <div class="textnr">
            <div class="title">
              低价订房
            </div>
            <div class="subtitle">
              酒店专属，免中间商，更多实惠
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />房价优享，更多专享活动
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />随时可退
            </div>
            <div class="text">
              <img src="~@/assets/duer/icon.jpg" mode="" />专属小程序酒店各门店通用
            </div>
          </div>
          <img class="img" src="https://static-1252855848.cos.ap-shanghai.myqcloud.com/duer/booking.jpg" mode="" />
        </div>
      </div>
    </div>
    <!-- 提示 -->
    <div class="message" v-if="message_state">
      {{message_text}}
    </div>
    <!--  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      QRCode: "",
      comkey: "",

      message_state: false,
      message_text: "",
    };
  },
  created() {
    document.title = "住酒店用住满多，越多服务越多精彩！";
    var comkey = this.getUrlParam("comkey");
    if (comkey) {
      this.comkey = comkey;
      this.req_QRCode(comkey);
    } else {
      // this.$info({
      //   centered: true,
      //   title: "提示",
      //   content: "当前没有设置参数",
      // });
    }
  },
  methods: {
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    req_QRCode(comkey) {
      var data = {
        path: "pages/loading?comkey=" + comkey + "&type=4",
        optional: {
          width: 410,
        },
      };
      var url = "https://9dpms.com/pms-user/duer-qrcode/ZMD?comkey=" + comkey;
      this.$Hrequest("POST", url, "", data).then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          let data = res.data;
          this.QRCode = "data:image/png;base64," + data.base;
        } else {
          let data = res.data;
          if (data.error) {
            this.onMmessage(data.error);
          } else {
            this.onMmessage("duer-qrcodet请求出错");
          }
        }
      });
    },
    onMmessage(test) {
      this.message_state = true;
      this.message_text = test;
      let time = setInterval((res) => {
        clearInterval(time);
        this.message_state = false;
      }, 1500);
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/duer/index.less";
</style>