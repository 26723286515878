<template>
  <div>
    <div class="cooperation">
      <h2>代理伙伴合作意向记录表</h2>
      <p>云店掌为酒店、民宿、综合多场景提供了数字化互联网解决方案，以管理为基础，突出运营，扩展商家收益途径。感谢您选择云店掌，请填写以下信息，便于我们尽快联系您。</p>
      <div class="content">
        <div :gutter="[10,10]">
          <div style="margin-bottom:10px;">
            <div>联系人</div>
            <input class="input" size="large" :maxLength="10" v-model="applyData.linkman" />
          </div>
          <div style="margin-bottom:10px;">
            <div>手机号码</div>
            <input class="input" size="large" :maxLength="11" type="tel" v-model="applyData.phone" />
          </div>
          <div style="margin-bottom:10px;">
            <div>选择地区</div>
            <div class="input" :maxLength="11" type="tel" @click="choice_state = true">
              <span v-if="province.label">
                {{province.label}}-{{city.label}}-{{area.label}}
              </span>
              <span v-else>请选择地区</span>
            </div>
          </div>
          <div style="margin-bottom:30px;">
            <div>意向产品</div>
            <div style="margin-bottom:10px;" v-for="(item,index) in IntendedProducts" :key="index">
              <label class="df ai_c">
                <input class="checkbox" v-model="item.state" type="checkbox" />
                <span style="font-size: 18px;"> {{item.name}}</span>
              </label>
            </div>
          </div>
          <div style="margin-bottom:30px;">
            <div class="button df ai_c jc_c" @click="submit_Data()">
              提交
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择地址 -->
    <div v-if="choice_state">
      <SelectRegion @onclick="onSelectRegion"></SelectRegion>
    </div>
    <!-- 提示 -->
    <div class="message" v-if="message_state">
      {{message_text}}
    </div>
    <!--  -->
    <div class="info_modalbj" v-if="infoModal_state">
      <div class="info_modal">
        <div class="Zcontent">我们会尽快安排相关人员与您取得联系！</div>
        <div class="Zan df ai_c jc_c" @click="closePage()">好的</div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import SelectRegion from "./Select_Region";
export default {
  components: {
    SelectRegion,
  },
  data() {
    return {
      province: {},
      city: {},
      area: {},

      IntendedProducts: [],
      applyData: {
        type: 2,
        linkman: "",
        phone: "",
        product: [],
        source: "线上",
        remark: "", //备注
      },
      //
      message_state: false,
      message_text: "",
      //
      infoModal_state: false,
      //
      choice_state: false,
    };
  },
  created() {
    document.title = "代理伙伴合作意向记录表";
    this.req_IntendedProducts();
  },
  methods: {
    req_IntendedProducts() {
      var url = "https://1880880.com/pms/platform-type-no-permission?group=IntendedProducts";
      this.$Hrequest("get", url, "", "").then((res) => {
        console.log(res);
        if (res.status >= 200 && res.status <= 300) {
          let data = res.data;
          if (data[0].group == "IntendedProducts") {
            let children = data[0].children;
            for (let i in children) {
              children[i].state = false;
            }
            this.IntendedProducts = children;
          }
        }
      });
    },
    on_product(e) {
      console.log(e);
    },
    submit_Data() {
      var info = JSON.stringify(this.applyData);
      info = JSON.parse(info);
      let product = [];
      let IntendedProducts = this.IntendedProducts;
      for (var i in IntendedProducts) {
        if (IntendedProducts[i].state) {
          product.push(IntendedProducts[i].name);
        }
      }
      info.product = product;

      if (!info.linkman) {
        this.onMmessage("请输入联系人");
        return;
      }
      if (!info.phone) {
        this.onMmessage("请输入联系人手机号");
        return;
      }
      if (!this.checkPhone(info.phone)) {
        this.onMmessage("联系人手机号格式错误");
        return;
      }
      if (!this.province.id) {
        this.onMmessage("请选择地区");
        return;
      } else {
        info.province = this.province.id;
        info.city = this.city.id;
        info.district = this.area.id;
      }
      if (info.product.length == 0) {
        this.onMmessage("请选择意向产品");
        return;
      }
      var url = "https://1880880.com/pms-recommend/consult";
      this.$Hrequest("POST", url, "", info).then((res) => {
        console.log(res);
        if (res.status >= 200 && res.status <= 300) {
          this.infoModal_state = true;
        } else {
          let data = res.data;
          console.log(data);
          console.log(data.error);
          if (data.error) {
            this.onMmessage(data.error);
          } else {
            this.onMmessage("请求：pms-recommend/consult出错");
          }
        }
      });
    },
    onMmessage(test) {
      this.message_state = true;
      this.message_text = test;
      let time = setInterval((res) => {
        clearInterval(time);
        this.message_state = false;
      }, 2000);
    },
    checkPhone(phone) {
      if (!phone) {
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    onSelectRegion(e) {
      this.choice_state = false;
      if (e.province) {
        this.province = e.province;
        this.city = e.city;
        this.area = e.area;
      }
    },
    closePage() {
      //安卓手机
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          WeixinJSBridge.call("closeWindow");
        },
        false
      );
      //ios手机
      WeixinJSBridge.call("closeWindow");
    },
  },
};
</script>

<style lang="less">
.cooperation {
  font-size: 18px;
  h2 {
    text-align: center;
    background: #003a79;
    color: #fff;
    padding: 10px;
    margin: 0;
  }
  p {
    background: #1890ff33;
    color: #003a79;
    padding: 20px;
    margin: 0;
    text-align: justify;
  }
  .input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
  }
  .checkbox {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .content {
    padding: 20px;
  }
  .ant-cascader-picker {
    font-size: 18px;
  }
  .button {
    height: 40px;
    background: #1890ff;
    border-radius: 5px;
    color: #fff;
  }
}
.ant-cascader-menu {
  height: 300px;
}
.ant-cascader-menus {
  font-size: 18px;
}
</style>