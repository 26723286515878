<template>
  <div class="Msolution_detailed">
    <!--  -->
    <div class="banner df ai_c jc_c" style="background-image: url('/imgs/1605091540298.jpg');">
      <h2 class="content df ai_c jc_c">{{name}}解决方案</h2>
    </div>
    <!--  -->
    <div class="modular_content">
      <!--  -->
      <div class="content-scroll" style="padding-top: 60px;">
        <p style="text-align:center;" class="MsoNormal" align="center"></p>
        <p></p>
        <p></p>
        <p> </p>
        <p style="text-align:center;" class="MsoNormal" align="center"><span style="font-size:36px"><strong><span style="line-height:4">前厅管理解决方案</span></strong></span></p>
        <p class="MsoNormal"> </p>
        <p><span style="font-size:20px"><strong><span style="line-height:1.2">一、正餐业态前厅管理难点</span></strong></span></p>
        <p>（1）纸质预订登记易出错，常常导致预订顾客体验差</p>
        <p>（2）人工点餐、下单、结账耗时耗力，高峰时段人力紧张</p>
        <p>（3）后厨缺乏高效管控，配菜、传菜效率低</p>
        <p>（4）营销缺乏新玩法，品牌老化印象难改观</p>
        <p class="MsoNormal"> </p>
        <p><span style="line-height:1.2"><span style="font-size:20px"><strong>二、正餐业态前厅管理解决方案介绍</strong></span></span></p>
        <p class="MsoNormal"><span style="font-size:18px"><strong><span style="line-height:1">1、餐前服务</span></strong></span></p>
        <p><span style="line-height:2">（1）订多多——多渠道客源聚合，支持多种预订方式</span></p>
        <p> 订多多不仅集成了销售APP、微信、智能预订电话等多渠道聚合预订功能，也与收银POS完全打通，可以更好地实现会员联动以及宴会预订等服务，有效增强高端顾客的预订体验感。目前，订多多已与美团、百度地图等平台打通。</p>
        <p class="MsoNormal"> </p>
        <p></p>
        <p class="MsoNormal"> </p>
        <p><span style="line-height:2">（2）云店掌排队等位系统——一站式排队管理，提升顾客等候体验</span></p>
        <p>排队等位系统帮助正餐企业实现排队电子化管理，还支持预点餐及落座后扫码下单，节省顾客等菜时间，提高翻台率。同时，餐厅还可在顾客排队期间，对会员进行多种营销服务，提高会员排队体验。</p>
        <p class="MsoNormal"> </p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"></p>
        <p></p>
        <p></p>
        <p class="media-wrap image-wrap"></p>
        <p></p>
        <p><span style="line-height:2">（3）云店掌POS系统——全方位聚合，实现点餐、接单、配送一体化</span></p>
        <p>云店掌POS可在页面中直接反馈散客桌台、包厢的餐位状态。此外，系统还聚合了饿了么、美团外卖等多种外卖平台及配送平台，实现点餐、接单、配送一体化。</p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"> </p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"></p>
        <p></p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"> </p>
        <p><span style="font-size:18px"><strong>2、餐中服务</strong></span></p>
        <p class="MsoNormal"> </p>
        <p><span style="line-height:2">（1）点菜宝——智能点菜系统，餐厅毛利提升“神器”</span></p>
        <p>依托点菜宝，服务员可以在桌边快捷完成点单、收银全流程。同时，点菜宝也支持会员查询、处置、会员权益使用，以及转台、并台等桌台操作。正餐场景下，点菜宝针对顾客已点餐品，可以实时计算当前订单毛利率，方便服务员为顾客推荐菜品。</p>
        <p class="MsoNormal"></p>
        <p class="media-wrap image-wrap align-center"></p>
        <p></p>
        <p></p>
        <p class="MsoNormal"> </p>
        <p><span style="line-height:2">（2）云店掌厨神系统——促进后厨工作流程优化，提高人效</span></p>
        <p>云店掌厨神系统是一款后厨提效神器，基于智能合单、估清同步、出品超时提醒、员工精准计件考核等功能，可有效帮助餐厅加强前厅与后厨的协同效率，提升后厨出餐效率，提升员工的工作积极性。</p>
        <p class="MsoNormal"></p>
        <p class="media-wrap image-wrap"></p>
        <p></p>
        <p><span style="font-size:18px"><strong>3、餐后服务</strong></span></p>
        <p style="text-align:center;" class="MsoNormal" align="center"> </p>
        <p><span style="line-height:2">（1）聚合支付——交易连接一切，打通多种支付场景</span></p>
        <p class="media-wrap image-wrap"></p>
        <p></p>
        <p></p>
        <p style="text-align:center;" class="MsoNormal" align="center"> </p>
        <p><span style="line-height:2">（2）云店掌电子发票——智能化高度集成，精简冗余成本</span></p>
        <p>云店掌电子发票支持结账单上多码合一自助开票，以及微信餐厅、小程序下单结算完成后自助开票，精简了发票从领用-核算的冗余成本。</p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"></p>
        <p width="333" height="232" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps19.jpg"></p>
        <p></p>
        <p><span style="font-size:18px"><strong><span style="line-height:1.2">4、CRM及营销解决方案</span></strong></span></p>
        <p><span style="line-height:2">（1）云店掌一体化营销解决方案——营销裂变组合拳，告别品牌老化</span></p>
        <p>打通线上线下会员，构建多维度会员标签画像，方便餐企进行会员分层管理以及精准化营销。同时，支持集团、门店等不同视角下的营销方案设置，拥有覆盖会员全生命周期的营销方案，助力餐企实现大数据驱动下的个性化精准营销，促进拉新复购，增强会员黏性。</p>
        <p class="MsoNormal"></p>
        <p width="553" height="277" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps20.jpg"></p>
        <p> </p>
        <p class="MsoNormal"> </p>
        <p><span style="line-height:2">（2）云店掌CRM方案架构——全渠道、多玩法，促进营业额有效提升</span></p>
        <p class="MsoNormal"></p>
        <p class="MsoNormal"></p>
        <p></p>
        <p><span style="line-height:2">（3）CRM解决方案营销活动——丰富多样的营销方式及接口</span></p>
        <p></p>
        <p></p>
        <p></p>
        <p><span style="line-height:2">（4）云店掌CEM评价管理体系——提高顾客满意度，增加复购</span></p>
        <p></p>
        <p></p>
        <p></p>
        <p class="MsoNormal"> </p>
        <p> </p>
        <p style="text-align:center;"><span style="font-size:36px"><span style="line-height:4"><strong>供应链管理解决方案 </strong></span></span></p>
        <p></p>
        <p><span style="font-size:20px"><strong>一、正餐业态供应链管理难点</strong></span></p>
        <p class="MsoNormal"> </p>
        <p>（1）总部、配送中心、门店、供应商协同能力差，供应链流程体系混乱</p>
        <p>（2）缺乏数据支撑，菜谱难优化、食材难管控</p>
        <p></p>
        <p><span style="font-size:20px"><strong>二、正餐业态供应链管理解决方案介绍</strong></span></p>
        <p><span style="font-size:18px"><strong><span style="line-height:1">1、云店掌门店宝——随时随地线上订货、验货、查库存 </span></strong></span></p>
        <p>移动端门店智能预估订货、验货、盘点、查看报表工具，可随时随地订货、验货、查库存，提高门店运营效率。</p>
        <p class="MsoNormal"></p>
        <p width="287" height="174" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps23.jpg"></p>
        <p></p>
        <p class="media-wrap image-wrap"></p>
        <p><strong><span style="line-height:2">2、云店掌智能预估系统——预估准确率高达89.33%</span></strong></p>
        <p>基于云店掌SaaS一体化产品体系，云店掌预估系统可以帮助餐厅准确预估营业情况与菜品销量，并提供“智能预估订货”计划，让餐厅合理采购并保持合理的库存量，减少浪费。</p>
        <p class="MsoNormal"> </p>
        <p class="MsoNormal"></p>
        <p></p>
        <p class="MsoNormal"> </p>
        <p><strong><span style="line-height:2">3、“3S”管理系统 —— 生产、仓储、运输高效管控</span></strong></p>
        <p class="MsoNormal"> </p>
        <p>云店掌“3S”管理是指MES中央厨房生产执行系统、WMS仓库管理系统、TMS运输管理系统，其覆盖了中央厨房、仓库、运输管等环节高效、精准管控。</p>
        <p class="MsoNormal"> </p>
        <p class="MsoNormal"></p>
        <p class="media-wrap image-wrap"></p>
        <p></p>
        <p></p>
        <p><strong><span style="line-height:2">4、供应商协同系统——无需纸质单据，数据在线流转，协同在线对账</span></strong></p>
        <p class="MsoNormal"> </p>
        <p>云店掌供应商协同系统是专为餐企打造的供应商协同管理平台。从餐企下单、供应商接单、出库，再到门店入库，无需纸质单据凭证，无需人工记账，实现全流程数据在线同步，避免漏账、错账，数据更精准，同时支持发起实时对账提醒，对账更高效。</p>
        <p></p>
        <p></p>
        <p></p>
        <p> </p>
        <p> <span style="line-height:2"><strong>5、云店掌成本卡管理系统——成本精细化管控</strong></span></p>
        <p class="MsoNormal"> </p>
        <p>设置菜品标准成本卡，打通前端收银系统，通过对比理论耗用与实际耗用，实现成本精细化管控。</p>
        <p></p>
        <p></p>
        <p></p>
        <p> <span style="line-height:2"><strong>6、云店掌实体供应链“二十二城”——代仓代配代加工服务</strong></span></p>
        <p class="MsoNormal"> </p>
        <p>目前，云店掌已在多地打造了实体城市共享中央厨房项目——“二十二城”，可为连锁餐饮企业提供半成品预制菜代加工服务以及代仓、代配服务，帮助企业突破地域扩张的天花板。</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p class="media-wrap image-wrap"></p>
        <p></p>
        <p style="text-align:center;" class="MsoNormal" align="center"><span style="font-size:36px"><span style="line-height:4"><strong>人事管理解决方案</strong></span></span></p>
        <p> <span style="font-size:20px"><strong>一、正餐业态人事管理难点</strong></span></p>
        <p class="MsoNormal"> </p>
        <p>（1）员工绩效不易评估，无法有效调整激励机制</p>
        <p>（2）招工难、成本高，员工流动性大</p>
        <p></p>
        <p><span style="font-size:20px"><strong>二、正餐业态人事管理解决方案介绍</strong></span></p>
        <p><strong><span style="font-size:18px"><span style="line-height:1.2">1、云店掌人事通——实现劳动力精细化管理</span></span></strong></p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"> </p>
        <p>云店掌人事通是一款集组织管理、员工管理、排班管理、考勤管理、绩效管理、薪资智能核算、日常管理等于一体的连锁餐饮人事管理工具，可帮助餐企实现跨门店人事管理、高效智能排班、核算薪资等人事管理目标。</p>
        <p class="MsoNormal"></p>
        <p width="553" height="312" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps26.jpg"></p>
        <p class="media-wrap image-wrap"></p>
        <p></p>
        <p></p>
        <p class="MsoNormal"> </p>
        <p><span style="font-size:18px"><strong><span style="line-height:2">2、云店掌运营驾驶舱——门店智能运营，告别管理盲区</span></strong></span></p>
        <p class="MsoNormal"> </p>
        <p>灵活设置看板，可以按照不同岗位的工作内容设置不同的看板内容。实现“关注工作重点”“提高工作效率”“减少培训时间”的目标。</p>
        <p class="MsoNormal"></p>
        <p width="349" height="197" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps27.jpg"></p>
        <p></p>
        <p class="MsoNormal"> </p>
        <p><span style="font-size:18px"><strong><span style="line-height:2">3、云店掌人效优化咨询服务——提升人效，降低用工成本 &nbsp;</span></strong></span></p>
        <p>云店掌人效优化咨询服务，主要围绕不同餐企的发展、运营情况，以及对其相关经营数据的分析、现场调研，从招聘、人效优化、人事稽核、计件考核等多方面为其输出针对性优化方案。</p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"></p>
        <p width="554" height="311" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps28.jpg"></p>
        <p></p>
        <p style="text-align:center;"><span style="font-size:36px"><strong><span style="line-height:4">集团管理解决方案</span></strong></span></p>
        <p><span style="font-size:20px"><strong>一、正餐业态集团管理难点</strong></span></p>
        <p class="MsoNormal"> </p>
        <p>（1）各系统数据不相通，无法进行多维度数据报表分析</p>
        <p>（2）多品牌、多区域业务不对接，无法统一管理</p>
        <p>（3）经营数据统计滞后，无法对门店运营进行及时调整决策</p>
        <p></p>
        <p><span style="font-size:20px"><strong>二、正餐业态集团管理解决方案介绍</strong></span></p>
        <p> <span style="font-size:18px"><strong><span style="line-height:1.2">1、云店掌老板通——实时查看营业数据</span></strong></span></p>
        <p>通过老板通，品牌方老板等管理者可随时随地通过移动端查看店铺、区域的实时营业数据，以及周、月各项汇总报表，通过数据及时调整经营策略。</p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"></p>
        <p width="461" height="431" src="file:///C:\Users\ADMINI~1\AppData\Local\Temp\ksohtml11412\wps30.jpg"></p>
        <p></p>
        <p> </p>
        <p><span style="font-size:18px"><strong><span style="line-height:2"> 2、云店掌BI决策分析系统——深挖数据价值，以数据驱动决策</span></strong></span></p>
        <p>云店掌BI决策分析系统为餐饮企业提供了集数据采集、加工、分析、展现的数据分析系统，通过数据中台，BI系统把各个业务数据打通并加工成丰富的业务报表，深度挖掘数据价值，帮助餐企负责人及时调整营业策略。</p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"> </p>
        <p style="text-align:justify;" class="MsoNormal" align="justify"></p>
        <p></p>
        <p></p>
        <p> </p>
        <p class="MsoNormal"> </p>
      </div>
      <!--  -->

    </div>

    <!--  -->
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      name: "",
      navlist: [],
    };
  },
  created() {
    this.name = this.$route.query.name;
  },
  mounted() {},
  methods: {},
  beforeDestroy() {},
};
</script>
  
  <style lang="less">
@import "~@/assets/less/solution_detailed.less";
</style>