import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios';


import VueWechatTitle from 'vue-wechat-title'
import {Hrequest} from './config/api.js'

Vue.prototype.$Hrequest = Hrequest
Vue.prototype.$axios = axios

Vue.directive('title', {
  inserted: function (el, binding){
    document.title = el.dataset.title
  }
})
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo);

Vue.prototype.$overallNews= Vue.prototype.$overallNews ||  new Vue()   

Vue.use(VueWechatTitle);
Vue.config.productionTip = false


router.beforeEach((to,from,next) => {
  console.log(to.path)
  next()
})
// if(process.env.NODE_ENV=='development' || document.domain=='9dpms.com'){
  new Vue({
    render: h => h(App),
    router,
  }).$mount('#app')
// }
