<template>
  <div class="df fd_c ai_c jc_c">
    <h1 style="margin-top:100px;">404</h1>
    <h6>很抱歉，您访问的页面不存在。</h6>
    <a-button type="primary" @click="on_login">
        返回主页
      </a-button>
  </div>
  <!-- <a-result status="404" title="404" sub-title="很抱歉，您访问的页面不存在。">
    <template #extra>
      
    </template>
  </a-result> -->
</template>
<script>
export default {
    methods: {
        on_login(){
            this.$router.push("/");
        }
    }
}
</script>

<style lang="less">
</style>