<template>
  <div class="Mindex df">
    <div class="left fauto">
      <div class="main_nav he100b df fd_c jc_sb">
        <div class="topnr df fd_c ai_c jc_c">
          <h2>云店掌</h2>
          <img class="logo" src="/img/logo.png" alt="" />
          <p>跨平台Saas综合酒店系统</p>
        </div>
        <div class="bottomnr df fd_c ai_c">
          <p>合作伙伴</p>
          <p>
            全访问管理的云店掌SaaS酒店系统，基于国内先进的酒店PMS系统打造，简单快速，方便易用！扫码免费获取！
          </p>
          <img class="QRCode" src="/img/erwm.jpg" alt="二维码" />
        </div>
      </div>
    </div>
    <div class="right fgrow">
      <!--  -->
      <div class="modular1">
        <div class="slidetxt">
          <span class="slidetitle">云店掌-全平台Saas酒店管理系统<br />
            Full platform SaaS hotel management system</span>
        </div>
        <img src="/img/dakehu.jpg" alt="" />
      </div>
      <!--  -->
      <div class="modular2 df jc_sb ai_c">
        <div>
          <span class="login">登录/</span><span class="login">注册</span>
        </div>
        <div class="df">
          <img src="/img/phone.png" alt="" class="loginbj" />
          <span class="phone">免费咨询热线：400-6710-880</span>
        </div>
      </div>
      <!--  -->
      <div class="modular3 df ai_c jc_sa">
        <div class="content">
          <h3>丰富的独立酒店POS管理系统</h3>
          <p>
            系统化的酒店管理系统有丰富的功能分支，应用非常宽泛，云店掌有独立的POS，让前台操作员专注于快速操作，帮助客人办理业务，支持OTA、身份证、打印机、门锁、公安上传、智能客控等等丰富专业的功能
          </p>
          <a class="button">立即使用</a>
        </div>
        <div class="content">
          <h3>集团连锁统计分析</h3>
          <p>
            云店掌核心支撑连锁集团酒店，对会员、协议客户、OTA等可以集团化管理、精准营销，丰富多维度的数据分析报表，为集团连锁酒店提供全面的数据支撑，为营销提供数据支持
          </p>
          <a class="button" target="_blank" href='http://pmsgroup.9dpms.com/'>立即使用</a>
        </div>
        <div class="content" style="border:none;">
          <h3>店长订房营销小程序</h3>
          <p>
            店长订房不仅仅是一套订房小程序，还能为客户把会员、优惠券、客房卖品服务、充值活动、住客中心（客人在房间可以续房、补押金、呼叫服务、退房等等）等多种营销和自助服务形成一套综合的智慧酒店平台，减低前台工作量，提高客人满意度！
          </p>
          <a class="button">立即使用</a>
        </div>
      </div>
      <div class="modular4">
        <div class="nr">
          <p>
            云店掌-全平台Saas酒店管理系统，不仅仅是一套简单的酒店管理系统，它在构架之初就以国内目前市面上存量排名靠前的PMS为基础，打造智慧酒店生态系统，包含了酒店POS、OTA、数据统计、联合营销、小度智能、银联商务、微信、小程序、支付、大数据等等，为酒店提供一套可全面管理和持续扩展的信息化系统！
          </p>
        </div>
      </div>
      <!--  -->
      <div class="modular5" :style="{backgroundImage: 'url(' + img + ')',backgroundSize: 'cover'}">
        <div class="title">
          <h2>我们的优势</h2>
          <h3>OUR ADVANTAGE</h3>
        </div>
        <div class="content">
          <div class="li">
            <h3><span>全面</span> comprehensive</h3>
            <p>
              不仅仅是酒店管理系统，还集合了智慧客控、OTA对接、联合营销、微信小程序、支付等等
            </p>
          </div>
          <div class="li">
            <h3><span>精准</span> accurate</h3>
            <p>云店掌高起点，酒店管理专家的专享调研，仅面向4星级以下的酒店用户群</p>
          </div>
          <div class="li">
            <h3><span>及时</span> timely</h3>
            <p>一切以云端数据为基础，以腾讯云为载体，分布式构架为使用者带来更实时的数据</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="modular6">
        <div class="title">
          <h2>六大模块及步骤</h2>
          <h3>6 MODELS AND STEPS</h3>
        </div>
        <div class="tabs">
          <div class="nav df ai_c jc_sb">
            <div class="li fgrow df ai_c jc_c" :class="[tabActive==1?'active':'']" @click="tabActive=1">行业分析</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive==2?'active':'']" @click="tabActive=2">品牌分析</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive==3?'active':'']" @click="tabActive=3">店铺分析</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive==4?'active':'']" @click="tabActive=4">营销分析</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive==5?'active':'']" @click="tabActive=5">宝贝分析</div>
            <div class="li fgrow df ai_c jc_c" :class="[tabActive==6?'active':'']" @click="tabActive=6">钻展分析</div>
          </div>
          <div class="content" v-if="tabActive==1">
            <div class="df fw_w">
              <div class='acol12'>1、精准统计到所有行业及每个子行业，每月交易量和销售额；</div>
              <div class='acol12'>2、查看每个行业、子行业的每月热销宝贝、热销店铺；</div>
              <div class='acol12'>3、精准统计到子行业中每个属性值所对应的交易量和销售额； </div>
              <div class='acol12'>4、行业属性交叉分析，通过对不同属性组合进行分析，指导生产、 指导订货，指导制定广告投放策略；</div>
              <div class='acol12'>5、精确查看每个品牌的每个属性值的交易量和销售额；</div>
            </div>
          </div>
          <div class="content" v-if="tabActive==2">
            <div class="df fw_w">
              <div class='acol12'>1、全面的品牌行业报表提供品牌各行业及子行业 的销量、销售额；</div>
              <div class='acol12'>2、查看详细品牌分类，做好品牌管理。</div>
              <div class='acol12'>3、品牌最热销宝贝、热销店铺一目了然；</div>
            </div>
          </div>
          <div class="content" v-if="tabActive==3">
            <div class="df fw_w">
              <div class='acol12'>1、通过产品关键字查找出行业中最强的竞争对手；</div>
              <div class='acol12'>2、每天密切跟踪竞争对手、自己店铺和分销渠道等任意店铺改名、上架、调价、成交每个记录；</div>
              <div class='acol12'>3、查看竞争对手、自己和分销渠道等任意店铺最近、本月和上月所有宝贝销量、均价和销售额；</div>
              <div class='acol12'>4、通过分析找到新的、最好的分销渠道；</div>
            </div>
          </div>

          <div class="content" v-if="tabActive==4">
            <div class="df fw_w">
              <a-col :span='12'>1、轻松查找销量飙升的店铺；</a-col>
              <a-col :span='12'>2、店铺销量飙升当天的每个宝贝的销量及营销方法及广告方法一目了然；</a-col>
              <a-col :span='12'>3、店铺每一天的销量及所有营销方法（降价/改名）及广告方法一目了然；</a-col>
              <a-col :span='12'>4、飙升宝贝每一天的销量及营销方法一目了然；</a-col>
            </div>
          </div>

          <div class="content" v-if="tabActive==5">
            <div class="df fw_w">
              <a-col :span='12'>1、按宝贝名关键字查找出行业中最热销的各种宝贝； </a-col>
              <a-col :span='12'>2、将宝贝归属到某一自定义的产品，也可以跟踪每个热销宝贝最近/本月/上月的价格、销量变化数据和趋势图；</a-col>
              <a-col :span='12'>3、按宝贝属性关键字查找出行业中的最热销的宝贝；</a-col>
              <a-col :span='12'>4、轻松地追踪所关注宝贝的调价、改名、成交情况，并能对代理商进行有效地管理； </a-col>
            </div>
          </div>
          <div class="content" v-if="tabActive==6">
            <div class="df fw_w">
              <a-col :span='12'>1、查看各钻展广告位形式，选取目标钻展位，点击查看详情。 </a-col>
              <a-col :span='12'>2、查看广告位具体画面图片、日期、投放分布、投放期间销售额。</a-col>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="modular7" :style="{backgroundImage: 'url(' + imgbj + ')',backgroundSize: 'cover'}">
        <div class="title">
          <h2>我们的客户</h2>
          <h3>OUR CLIENTS</h3>
          <p>超过30,000家各行业品牌电商，开通了我们大数据服务账号</p>
        </div>
        <div class="content df fw_w">
          <div class="ant-col" v-for="(item,index) in list" :key="index">
            <div class="img df ai_c jc_c">logo</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="modular8">
        <p>湖南明谷网络科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备19028092号-2</a></p>
        <p style="text-align: left;">明谷网络科技是上海余乐公司的全资子公司，自2006年至今15年，是一家专门智慧酒店系统平台开发的软件厂家，公司开发的云店掌系列智能化酒店系统，涵盖酒店PMS，订房小程序，酒店线上商城，会员体系，智慧客控等核心子系统，为酒店提供全方位的管理、运营、统计平台！</p>
      </div>
      <!--  -->
    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      img: "/img/ysbj2.png",
      imgbj: "/img/mkbj.png",
      tabActive:1,
      list: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    };
  },
};
</script>
<style lang="less">
@import "~@/assets/less/index.less";
</style>