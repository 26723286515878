<template>
  <div class="Mhomestay">
    <div class="banner" style="background-image: url('/imgs/homestay/banner.jpg');">
      <div class="content df ai_c jc_c">
        <div class="frame">
          <h2 class="df ai_c jc_c">全国首家 专门为民宿景区打造的数字互联网管理+运营平台</h2>
          <div class="df ai_c jc_c">
            <p>为景区提供全程自助了解各种游玩场景、购买的小程序平台</p>
          </div>
          <!-- <div class="df ai_c jc_c" style="margin-top:30px">
            <img style="width:400px" src="/imgs/homestay/banner_img2.png" alt="">
          </div> -->
        </div>
      </div>
    </div>
    <!-- 售卖平台 -->
    <div class="modular1">
      <div class="content">
        <div class="title">为民宿专门设计的多部门券码核销和售卖平台</div>
        <ul class="list df">
          <li>
            <div class="img df fd_c ai_c jc_c">
              <div class="df ai_c jc_c" style="height:120px;"><img src="/imgs/homestay/gnicon1.png" alt=""></div>
              <h3>客房/木屋</h3>
            </div>
            <div class="lis">
              <p>住宿是云店掌核心的业务</p>
              <p>支持智能门锁远程开锁、锁定自动计算房价，多种计费模式</p>
              <p>支持微信支付宝担保交易</p>
              <p>实时房态随处可以查看</p>
              <p>可以联合微信小程序给客人续期、补交押金、销券等等移动业务</p>
            </div>
          </li>
          <li>
            <div class="img df fd_c ai_c jc_c">
              <div class="df ai_c jc_c" style="height:120px;"><img src="/imgs/homestay/gnicon2.png" alt=""></div>
              <h3>帐篷/天幕</h3>
            </div>
            <div class="lis">
              <p>支持场地租赁模式</p>
              <p>支持按次购买</p>
              <p>支持计时收费，可以租赁帐篷自带帐篷等不同收费模式</p>
              <p>支持线上购券，线下核销</p>
            </div>
          </li>
          <li>
            <div class="img df fd_c ai_c jc_c">
              <div class="df ai_c jc_c" style="height:120px;"><img src="/imgs/homestay/gnicon3.png" alt=""></div>
              <h3>房车/KTV</h3>
            </div>
            <div class="lis">
              <p>支持房车智能钥匙管理</p>
              <p>支持按次、计时等收费模式</p>
              <p>房车内支持扫码购买商品服务员送达</p>
              <p>计费模式也支持KTV计费等场景</p>
            </div>
          </li>
          <li>
            <div class="img df fd_c ai_c jc_c">
              <div class="df ai_c jc_c" style="height:120px;"><img src="/imgs/homestay/gnicon4.png" alt=""></div>
              <h3>钓鱼/烧烤</h3>
            </div>
            <div class="lis">
              <p>线上购买钓鱼券，分时分类计价，支持押金模式（担保交易）</p>
              <p>超时后自动计费</p>
              <p>结算时押金扣除，余款自动原路返回</p>
              <p>支持钓鱼点购买渔具、商品</p>
            </div>
          </li>
          <li>
            <div class="img df fd_c ai_c jc_c">
              <div class="df ai_c jc_c" style="height:120px;"><img style="max-width:90%;" src="/imgs/homestay/gnicon5.png" alt=""></div>
              <h3>游泳/滑翔伞/热气球</h3>
            </div>
            <div class="lis">
              <p>支持游泳、露天游泳场地</p>
              <p>支持线上购买券</p>
              <p>按次、计时计费都可以支持</p>
              <p>也支持押金模式</p>
              <p>游泳场所可以用手机售卖游泳衣、游泳圈等本场所商品</p>
              <p>也支持儿童乐园、水上乐园等场景</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 处理业务 -->
    <div class="modular2">
      <div class="content">
        <div class="title">支持多种民宿场景，不需要电脑，手机就可以处理业务</div>
        <ul class="list df fw_w">
          <li class="df ai_c">
            <div class="fauto img df fd_c ai_c jc_c" style="background:#FFC623;">
              <img src="/imgs/homestay/gnicon6.png" alt="">
              <h3>特色商品</h3>
            </div>
            <div class="fgrow">
              <p><span class="iconfont">&#xec1e;</span>在任何营业点均可支持线上购买线下提货</p>
              <p><span class="iconfont">&#xec1e;</span>支持购买后由营业点送达</p>
              <p class="df">
                <span class="iconfont">&#xec1e;</span>
                <span>支持现场购买，不需要电脑用手机即可完成所有业务、核销券码</span>
              </p>
            </div>
          </li>
          <li class="df ai_c">
            <div class="fauto img df fd_c ai_c jc_c" style="background:#FF5938;">
              <img src="/imgs/homestay/gnicon7.png" alt="">
              <h3>餐饮</h3>
            </div>
            <div class="fgrow">
              <p><span class="iconfont">&#xec1e;</span>客人扫桌台码自助点餐</p>
              <p><span class="iconfont">&#xec1e;</span>工作人员微信为客人点餐</p>
              <p><span class="iconfont">&#xec1e;</span>电脑点餐，均可支持，多种菜系自动分单厨房打印</p>
              <p><span class="iconfont">&#xec1e;</span>支持AB菜价</p>
              <p><span class="iconfont">&#xec1e;</span>支持多种点单场景，比如<b>茶吧、烧烤、小酒吧</b>等</p>
            </div>
          </li>
          <li class="df ai_c">
            <div class="fauto img df fd_c ai_c jc_c" style="background:#49B346;">
              <img src="/imgs/homestay/gnicon8.png" alt="">
              <h3>门票/充值活动</h3>
            </div>
            <div class="fgrow">
              <p><span class="iconfont">&#xec1e;</span>线上自助购票</p>
              <p class="df">
                <span class="iconfont">&#xec1e;</span>
                <span>购买后可由工作人员扫码销券进入景区，或由闸机扫码进入均支持</span>
              </p>
              <p><span class="iconfont">&#xec1e;</span>支持入园充值卡，充值多少赠送多少，支持退还</p>
            </div>
          </li>
          <li class="df ai_c">
            <div class="fauto img df fd_c ai_c jc_c" style="background:#1890ff;">
              <img src="/imgs/homestay/gnicon9.png" alt="">
              <h3>小木屋</h3>
            </div>
            <div class="fgrow">
              <p><span class="iconfont">&#xec1e;</span>体验式农家乐，租赁房间，租客可以自行体验农家乐趣</p>
              <p><span class="iconfont">&#xec1e;</span>多种计费方式</p>
              <p><span class="iconfont">&#xec1e;</span>扫码租赁、智能房卡</p>
              <p><span class="iconfont">&#xec1e;</span>房内二维码可以了解民宿内所有景点、营业场所、特色等等</p>
              <p><span class="iconfont">&#xec1e;</span>可以直接购买，现场核销</p>
            </div>
          </li>
          <li class="df ai_c" style="width:100%;">
            <div class="fauto img df fd_c ai_c jc_c" style="background:#4e6ef2;">
              <img style="max-width:80%;" src="/imgs/homestay/gnicon10.png" alt="">
              <h3>小度智慧</h3>
            </div>
            <div class="fgrow">
              <p><span class="iconfont">&#xec1e;</span>云店掌与百度旗下的智慧音箱小度做了深度对接,</p>
              <p><span class="iconfont">&#xec1e;</span>可以实现智慧客房语音控制窗帘、灯光、电视、空调等设备，可以与客服沟通需求，提升客户体验感！</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="modular3">
      <div class="content df ai_c jc_sb">
        <div class="circular1"></div>
        <div class="circular2"></div>
        <div class="circular3"></div>
        <div class="circular4"></div>
        <div class="circular5"></div>
        <div class="left df ai_c jc_c">
          <div class="iconfont">&#xe89a;</div>
        </div>
        <div class="right">
          <div class="title">计时类场景</div>
          <h3>多种自定义的计时模式</h3>
          <h3>按小时、起始数、按日租</h3>
          <p>押金模式，微信支付宝余款自动退</p>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="modular4">
      <div class="content df jc_sb">
        <div class="img fauto">
          <img src="/imgs/homestay/modular4bj.jpg" alt="">
        </div>
        <ul class="fgrow df fd_c jc_sb">
          <li class="df ai_c" style="background:#dd83009e;">
            <div class="icon fauto df fd_c ai_c jc_c" style="background:#dd8300;">
              <div class="iconfont df ai_c jc_c">&#xe6e2;</div>
              <h3>线上预订</h3>
            </div>
            <p>微信小程序线上预订系统，淡旺季随时调价，丰富的文案模板，快速发布预订</p>
          </li>
          <li class="df ai_c" style="background:#009f4c9e;">
            <div class="icon fauto df fd_c ai_c jc_c" style="background:#009f4c;">
              <div class="iconfont df ai_c jc_c">&#xe8b0;</div>
              <h3>分享收益</h3>
            </div>
            <p>自动生成朋友圈海报，分享在朋友圈，购买使用后享受收益，全面营销互联网思路</p>
          </li>
          <li class="df ai_c" style="background:#0061ba9e;">
            <div class="icon fauto df fd_c ai_c jc_c" style="background:#0061ba;">
              <div class="iconfont df ai_c jc_c">&#xe68a;</div>
              <h3>客房管理</h3>
            </div>
            <p>云店掌PMS接单、入住、自动计算房价、延住、房务、商品、结算、财务报表、实时房态SAAS构架，随时监管</p>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="modular5">
      <div class="content df ai_c jc_sb">
        <div class="left">
          <h3 class="df ai_c">
            <span class="shape"></span>
            <span>帐篷、天幕</span>
          </h3>
          <p style="color:#D8663E;font-weight:bold;">云店掌SAAS系列，为分散、户外空旷型的消费场景，提供了成熟的解决方案</p>
          <div class="df">
            <span class="iconfont">&#xe631;</span>
            <p> <b>首先</b>，帐篷租赁的<span>计费多样性</span>，我们给与了充分支持。不管是按次、按时、或者结合两者，系统都能很好地自动计算费用，全程不需要人员干预。确保管理者设定的规则，很好地执行；</p>
          </div>
          <div class="df">
            <span class="iconfont">&#xe631;</span>
            <p><b>其次</b>，帐篷分散，客人需要服务不方便，云店掌结合入住通小程序，只需要把入住通的二维码贴在帐篷上，客人扫一扫，不仅可以叫<span>服务、买商品、购买其他场地券</span>，还可以自己续费，提交结算，极大地方便了客人，也提高了商家的服务质量和效率。</p>
          </div>
        </div>
        <div class="img fauto">
          <img src="/imgs/homestay/modular5bj.jpg" alt="">
        </div>
      </div>
    </div>
    <!--  -->
    <div class="modular6">
      <div class="content">
        <ul class="df jc_sb">
          <li>
            <h3>
              <span class="iconfont">&#xe600;</span>
              <span>泳池/乐园/滑翔伞/热气球</span>
              <span class="iconfont">&#xe600;</span>
            </h3>
            <div class="img"><img src="/imgs/homestay/mk61.png" alt=""></div>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>微信小程序购买场所券</span>
            </p>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>抵达后由工作人员或者闸机销券进入使用</span>
            </p>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>各种场所工作人员可以用店长助手进行销券或者销售商品</span>
            </p>
          </li>
          <li>
            <h3>
              <span class="iconfont">&#xe600;</span>
              <span>房车租赁、线上商品</span>
              <span class="iconfont">&#xe600;</span>
            </h3>
            <div class="img"><img src="/imgs/homestay/mk62.png" alt=""></div>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>对于计时类的场所，入住通支持押金模式</span>
            </p>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>客人进入使用开始计费</span>
            </p>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>离场时汇总费用，扣除消费余款自动原路退回</span>
            </p>
          </li>
          <li>
            <h3>
              <span class="iconfont">&#xe600;</span>
              <span>烧烤/钓鱼</span>
              <span class="iconfont">&#xe600;</span>
            </h3>
            <div class="img"><img src="/imgs/homestay/mk63.png" alt=""></div>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>张贴入住通二维码，客人扫一扫，支付后由服务人员接单送达</span>
            </p>
            <p class="df">
              <span class="iconfont">&#xe603;</span>
              <span>也可以在附近的小卖部实现线上购买，线下核销</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="modular7">
      <div class="content">
        <div class="title">
          <h2>更多项目支持</h2>
          <p>入住通+店长助手，不需要购买电脑,手机可以直接操作</p>
        </div>
        <ul class="list df ai_c jc_sb">
          <li class="fauto">
            <div class="img">
              <img src="/imgs/homestay/mk71.png" alt="">
              <h3>热气球</h3>
            </div>
          </li>
          <li class="fauto">
            <div class="img">
              <img src="/imgs/homestay/mk72.png" alt="">
              <h3>漂流</h3>
            </div>
            <div class="img">
              <img src="/imgs/homestay/mk73.png" alt="">
              <h3>KTV唱吧</h3>
            </div>
          </li>
          <li>
            <div class="img">
              <img src="/imgs/homestay/mk74.png" alt="">
              <h3>采摘体验</h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="modular8">
      <div class="content">
        <div class="title">
          <h2>更多项目支持</h2>
          <p>入住通+店长助手，不需要购买电脑,手机可以直接操作</p>
        </div>
        <div class="list df jc_sb">
          <div class="li1 fgrow">
            <div class="img">
              <h3>检票闸机</h3>
              <img src="/imgs/homestay/mk81.png" alt="">
            </div>
          </div>
          <div class="mk2 rbj">
            <div class="li2 bbj">
              <div class="img">
                <h3>儿童乐园</h3>
                <img src="/imgs/homestay/mk82.png" alt="">
              </div>
            </div>
            <div class="li2">
              <div class="img">
                <h3>集市体验</h3>
                <img src="/imgs/homestay/mk83.png" alt="">
              </div>
            </div>
          </div>
          <div class="mk3">
            <div class="li2 bbj">
              <div class="img">
                <h3>民俗活动</h3>
                <img src="/imgs/homestay/mk84.png" alt="">
              </div>
            </div>
            <div class="li2">
              <div class="img">
                <h3>特色商品</h3>
                <img src="/imgs/homestay/mk85.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="ChargingBox">
      <div class="img">
        <img src="/imgs/exhibition/cdhz.jpg" alt="">
      </div>
      <div class="introduce">
      </div>
    </div>
    <!--  -->
    <div class="modular9">
      <div class="content  df ai_c jc_c">
        <div>
          <div class="df ai_c jc_sb" style="margin-bottom:20px;">
            <h2>7<small>x</small>24<small>小时</small></h2>
            <h2>400-6710-880</h2>
          </div>
          <p>180-190-39339 (微信同号)</p>
          <p>无论遇到什么问题，请拨打全国统一服务热线，我们将随时提供支持</p>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="modular10">
      <ul class="df ai_c jc_sb">
        <li class="fgrow df fd_c ai_c jc_c">
          <img src="/imgs/kefu.png" alt="">
          <h6>客服在线</h6>
        </li>
        <li class="fgrow df fd_c ai_c jc_c">
          <img src="/imgs/yuancheng.png" alt="">
          <h6>远程在线</h6>
        </li>
        <li class="fgrow df fd_c ai_c jc_c">
          <img src="/imgs/xianchang.png" alt="">
          <h6>现场服务</h6>
        </li>
      </ul>
    </div>
    
    <!--  -->
  </div>
</template>

<script>
export default {};
</script>
<style lang="less">
@import "~@/assets/less/homestay.less";
</style>