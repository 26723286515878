
import axios from 'axios';
axios.defaults.timeout = 50000//默认请求超时时间

export function Hrequest(method, url, head ={}, data = {},Tips=true){
    return new Promise((resolve, reject) => {

        var headers = { "content-type": "application/json" }
        if(head){
            headers = {...headers,...head};
        }
        if(headers.Authorization){
            var userkey = this.$store.getters.dataValue({name:"userkey",state: false})
            if(userkey.token){
                headers.Authorization = userkey.token
            }
        }
        if (JSON.stringify(data) == "{}"){
            data = ''
        }
        if (data.headers_Form){
            headers = {...headers,...{'Content-Type': 'multipart/form-data'}};
            let fileImg = data.FormData
            var fd = new FormData()
            if(fileImg){
                fd.append('file',fileImg,+"1111.jpg")
            }
            
            if(data.path){
                fd.append('path',data.path)
            }
            if(data.filename){
                fd.append('filename',data.filename)
            }
            if(data.driver){
                fd.append('driver',data.driver)
            }
            if(data.file){
                fd.append('file',data.file)
            }
            if(data.type){
                fd.append('type',data.type)
            }
            if(data.title){
                fd.append('title',data.title)
            }
            if(data.desc){
                fd.append('desc',data.desc)
            }

            let config = {
                headers: headers
            }
            axios.post(url, fd,config).then(res => {
                if(res.headers.authorization){
                    var userkey = this.$store.getters.dataValue({name:"userkey",state:false})
                    userkey.token = res.headers.authorization
                    this.$store.commit("dataValue", {name: "userkey",value:userkey});
                }
                //获取值
                var info = ''
                if(res.data){
                    info = res.data
                }
                
                resolve(res)
                // ResultReturn(this,info,res.status,Tips,res=>{
                // })
            }).catch(res => {
                if(res.request.status==0){
                    this.$message.error('网络链接错误无法连接后台');
                    resolve(false)//返回失败
                    return 
                }
                var headers = res.response.headers;
                if(headers.authorization){
                    var userkey = this.$store.getters.dataValue({name:"userkey",state:false})
                    userkey.token = headers.authorization
                    this.$store.commit("dataValue",{name: "userkey",value: userkey});
                }
                //获取值
                var info = ''
                if(res.response.data){
                    info  = res.response.data;
                }
                resolve(res.response)
                // ResultReturn(this,info,res.response.status,Tips,res=>{
                //     resolve(res)
                // })
            })
            
        } else {
            this.$axios({
                method: method,
                url: url,
                data: data,
                headers: headers
            }).then((res) => {
                console.log('then');
                // console.log(res.status)
                if(res.headers.authorization){
                    var userkey = this.$store.getters.dataValue({name:"userkey",state:false})
                    userkey.token = res.headers.authorization
                    this.$store.commit("dataValue", {name: "userkey",value: userkey});
                }
                var info = ''
                if(res.data){
                    info = res.data
                }
                resolve(res)
                // ResultReturn(this,info,res.status,Tips,res=>{
                //     resolve(res)
                // })
            }).catch((res) => {
                if(res.request.status==0){
                    this.$message.error('网络链接错误无法连接后台');
                    resolve(false)//返回失败
                    return 
                }
                console.log('catch');
                var headers = res.response.headers;
                if(headers.authorization){
                    var userkey = this.$store.getters.dataValue({name:"userkey",state:false})
                    userkey.token = headers.authorization
                    this.$store.commit("dataValue", {name: "userkey",value: userkey});
                }
                var info = ''
                if(res.response.data){
                    info  = res.response.data;
                }
                resolve(res.response)
                // ResultReturn(this,info,res.response.status,Tips,res=>{
                //     resolve(res)
                // })
            })
        }
    })
}

function ResultReturn(_this,data,status,Tips,success){
    var info = data
    if(info.error=='未提供令牌' || info.error=='登录过期,重新登录'){
        _this.$router.push("/");
        return
    }
    //返回
    if(info){
        if(info.code){
            success(false)//返回成功
        }else if(status>=200 && status<=300){
            info = JSON.stringify(info)
            info = JSON.parse(info)
            success(info)//返回成功
        }
    }else{
        if(status>=200 && status<=300){
            success(true)//返回成功
        }else{
            success(false)//返回失败
        }
    }
    //提示
    // if(status>=200 && status<=300){
    //     Tips = true
    // }
    // if(Tips){
    //     if(Tips && Tips!==true && Tips!=='false'){
    //         _this.$message.error(Tips);
    //     }else if(Tips=='false'){

    //     } else if(info.error && status>300){
    //         _this.$message.error(info.error);
    //     }else if(status>=400){
    //         _this.$message.error('这是一条请求错误');
    //     }
    // }
}