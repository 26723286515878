<template>
  <div class="Msolution">
    <!--  -->
    <div class="banner df ai_c jc_c" style="background-image: url('/imgs/1605091540298.jpg');">
      <h2 class="content df ai_c jc_c">酒店全业态解决方案满足所有场景需求</h2>
    </div>
    <!--  -->
    <div class="modular1">
      <ul class="list df ai_c jc_c">
        <li class="df fd_c ai_c" v-for="(item,index) in navlist" :key="index" @click="on_tabli(item)">
          <div class="img df ai_c jc_c">
            <img :src="item.img" alt="">
          </div>
          <p>{{item.name}}</p>
        </li>
      </ul>
    </div>
    <!--  -->
    <div class="modular2">
      <div v-for="(item,index) in navlist" :key="index" :class="'li'+index+1">
        <div class="title df ai_c jc_sb">
          <h2>{{item.name}}</h2>
          <p @click="on_li(item)">{{item.name}}解决方案 ></p>
        </div>
        <ul class="list df fw_w ai_c">
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
              <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
              <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
          <li class="df fd_c" @click="on_lis(item)">
            <div class="img df ai_c jc_c">
              <img src="/imgs/61a594c75101f.png" alt="">
            </div>
            <p>酒店名称</p>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      navlist: [
     
        {
          name: "客房",
          img: "/imgs/20221116092613.png",
          top:0
        },
      {
          name: "餐厅茶室",
          img: "/imgs/20221116092614.png",
          top:0
        },
        {
          name: "门票/游泳/健身",
          img: "/imgs/20221116092615.png",
          top:0
        },
        {
          name: "租赁",
          img: "/imgs/20221116092616.png",
          top:0
        },
        {
          name: "房车",
          img: "/imgs/20221116092617.png",
          top:0
        },
        {
          name: "周边商家",
          img: "/imgs/20221116092618.png",
          top:0
        },
        {
          name: "漂流、儿童乐园",
          img: "/imgs/20221116092619.png",
          top:0
        },
        {
          name: "热气球、滑翔伞",
          img: "/imgs/20221116092620.png",
          top:0
        },{
          name: "烧烤、帐篷",
          img: "/imgs/20221116092621.png",
          top:0
        },
      ],
    };
  },
  created() {},
  mounted() {
    let time = setTimeout(() => {
      this.get_litop();
      clearTimeout(time);
    },500);
  
  },
  methods: {
    on_tabli(item){
      console.log(item)
      document.documentElement.scrollTop = item.top-80;
    },
    on_li(item){
      document.documentElement.scrollTop = 0;
      this.$router.push({ path:'/solution_detailed.html',query:{name:item.name}});
    },
    on_lis(item){
      document.documentElement.scrollTop = 0;
      this.$router.push({ path:'/solution_case.html',query:{name:'酒店名称'}});  
    },
    get_litop(){
      let list = this.navlist;
      for(let i in list){
        if(!list[i].top){
          let top = document.querySelector('.li'+i+1);
          list[i].top  = top.offsetTop
        }
      }
      console.log(list)
      this.navlist = list;
    }
  },
  beforeDestroy() {},

};
</script>

<style lang="less">
@import "~@/assets/less/solution.less";
</style>