<template>
  <div class="">
    <!-- 顶部菜品 -->
    <div class="headerPage" v-if="nav_state" @click="contactUs_state = false;Select_Area_state = false;">
      <div class="header df ai_c jc_sb">
        <div class="logo df ai_c jc_c" @click="on_navli('/')">
          <img style="width:60px" src="/img/logo.png" alt="" />
          <h2>云店掌</h2>
        </div>
        <div class="iconfont" @click="navr_state = true">&#xe790;</div>
        <ul class="nav df ai_c">
          <li @click="on_navli('/')">首页</li>
          <li @click="on_navli('/product.html')">产品展示</li>
          <li @click="on_navli('/homestay.html')">景区民宿</li>
          <li @click="on_navli('/ylt.html')">余乐通</li>
          <li @click="on_navli('/about_us.html')">关于我们</li>
          <!-- <li style="background:#f00;padding:5px 20px;border-radius:20px;" @click="Download_state = true;">免费试用</li> -->
          <li style="background:#f00;padding:5px 20px;border-radius:20px;" @click="on_scrollTop()">免费试用</li>
        </ul>
        <div class="tel">
          400-6710-880
        </div>
      </div>
      <div  class="navr df fd_c jc_sb" v-if="navr_state">
        <div class="fauto" style="height:65px;" @click="navr_state= false"></div>
        <ul class="fauto">
          <li @click="navr_state = false,on_navli('/')">首页</li>
          <li @click="navr_state = false,on_navli('/product.html')">产品展示</li>
          <li @click="navr_state = false,on_navli('/homestay.html')">景区民宿</li>
          <li @click="navr_state = false,on_navli('/about_us.html')">关于我们</li>
          <li style="background:#f00;color:#fff;padding:5px 20px;border-radius:20px;" @click="navr_state = false,Download_state = true;">免费试用</li>
        </ul>
        <div class="fgrow" @click="navr_state= false"></div>
      </div>
    </div>
    <!--  -->
    <div @click="contactUs_state = false;">
      <router-view />
    </div>
    <!-- 底部 -->
    <div class="footer" @click="contactUs_state = false;">
      <div class="content">
        <ul class="list df jc_sb">
          <li  v-if="URL_name!='/ylt.html'">
            <h5 class="df ai_c">

              云店掌微信公众号</h5>
            <p>微信扫一扫关注</p>
            <div>
              <p><img class="QRCode" src="/img/erwm.jpg" alt="二维码" /></p>
            </div>
          </li>
          <li class="lxfs" style="width:45%;" v-if="URL_name=='/ylt.html'">
            
            <h5 class="df ai_c" style="margin-bottom:0;"><span class="iconfont">&#xe666;</span> 服务地址</h5>
            <p>上海市杨浦区国定路335号8010-16室</p>
            <h5 class="df ai_c" style="margin-bottom:0;"><span class="iconfont">&#xec65;</span>小度在酒店</h5>
            <p>可实现主动迎宾、引领讲解、问答咨询、互动娱乐等功能。</p>
          </li>
          <li class="lxfs" v-else>
            <h5 class="df ai_c" style="margin-bottom:0;"><span class="iconfont">&#xe616;</span> 服务热线</h5>
            <p>400-6710-880<span style="padding-left:20px;">180-190-39339 (微信同号)</span></p>
            <h5 class="df ai_c" style="margin-bottom:0;"><span class="iconfont">&#xe666;</span> 服务地址</h5>
            <p>上海市杨浦区国定路335号8010-16室</p>
            <h5 class="df ai_c" style="margin-bottom:0;"><span class="iconfont">&#xec65;</span>小度在酒店</h5>
            <p>可实现主动迎宾、引领讲解、问答咨询、互动娱乐等功能。</p>
          </li>
          <li class="lxfs" v-if="URL_name=='/ylt.html'">
            <h5 class="df ai_c" style="margin-bottom:0;"><span class="iconfont">&#xe616;</span> 服务热线</h5>
            <p>400-6710-880<span style="padding-left:20px;">180-190-39339 (微信同号)</span></p>
            <p>自2006年创立开始，余乐通就一直致力于酒店行业的管理系统探索和研发，我们定位中档酒店智能化管理系统，十四年来我们接触到近万家酒店、酒店管理公司，我们非常感谢这些企业给与我们的大力支持和建议！这些宝贵意见成为余乐通产品的一部分，让我们一系列产品极具生命力！</p>
          </li>
          <li  v-else>
            <h5>整体解决方案咨询与免费体验</h5>
            <div class="input df ai_c" @click="on_choice_address()">
              <span style="color:#757575;" v-if="province.length && city.length && area.length">{{province[region_active1].label}} / {{city[region_active2].label}} / {{area[region_active3].label}}</span>
              <span style="color:#757575;" v-else>请选择地区</span>
            </div>
            <input class="input" v-model="submit_data.phone" type="text" maxlength="11" placeholder="请输入联系电话">
            <div class="submit_an df ai_c jc_c" @click="on_submit_apply()">免费申请</div>
          </li>
        </ul>
        <div class="copyright df ai_c jc_sb">
          <div  v-if="URL_name=='/ylt.html'">余乐通</div>
          <div  v-else>云店掌</div>
          <div v-if="document_domain=='1880880.com' || document_domain=='www.1880880.com'">
            上海余乐计算机科技有限公司 <a href="https://beian.miit.gov.cn/">沪ICP备15047937号-1</a>
          </div>
          <div v-else-if="document_domain=='qugongyu.com' || document_domain=='www.qugongyu.com'">
            上海余乐计算机科技有限公司 <a href="https://beian.miit.gov.cn/">沪ICP备15047937号-5</a>
          </div>
          <div v-else-if="document_domain=='517hotel.com' || document_domain=='www.517hotel.com'">
            上海余乐计算机科技有限公司 <a href="https://beian.miit.gov.cn/">沪ICP备15047937号-6</a>
          </div>
          <div v-else-if="document_domain=='9dpms.com' || document_domain=='www.9dpms.com'">
            湖南明谷网络科技有限公司 <a href="https://beian.miit.gov.cn/">湘ICP备19028092号-2</a>
          </div>
          <div v-else>
            上海余乐计算机科技有限公司 <a href="https://beian.miit.gov.cn/">沪ICP备15047937号-1</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择地址 -->
    <div class="choice_address df jc_sb" v-if="Select_Area_state">
      <div class="fgrow" @click="Select_Area_state=false"></div>
      <div class="df fd_c jc_sb">
        <div class="fgrow" @click="Select_Area_state=false"></div>
        <div class="content fauto">
          <div style="height:40px;background:#222;border-bottom:1px solid #2e2e2e;" class="df ai_c jc_c">您酒店所在地区</div>
          <div class="df jc_sb">
            <div class="list">
              <div class="li df ai_c jc_c" :class="[region_active1==index?'active':'']" v-for="(item,index) in province" :key="index" @click="on_region_list1(index)">{{item.label}}</div>
            </div>
            <div class="list">
              <div class="li df ai_c jc_c" :class="[region_active2==index?'active':'']" v-for="(item,index) in city" :key="index" @click="on_region_list2(index)">{{item.label}}</div>
            </div>
            <div class="list">
              <div class="li df ai_c jc_c" :class="[region_active3==index?'active':'']" v-for="(item,index) in area" :key="index" @click="on_region_list3(index)">{{item.label}}</div>
            </div>
          </div>
          <div style="height:40px;background:#222;color:#fff;margin-top:2px;cursor:pointer;" class="df ai_c jc_c" @click="Select_Area_state = false">确定选择</div>
        </div>
        <div class="fgrow" @click="Select_Area_state=false"></div>
      </div>
      <div class="fgrow" @click="Select_Area_state=false"></div>
    </div>
    <!--  -->
    <!-- 联系我们 -->
    <div class="McontactUs" v-if="URL_name!='/ylt.html'" @click="contactUs_state = true">
      <div class="df fd_c ai_c jc_c" style="user-select:none;">
        <p class="iconfont">&#xe637;</p>
        <p>联系我们</p>
      </div>
    </div>
    <div class="McontactUs_Popup df ai_c jc_sb" :class="[contactUs_state?'display':'']">
      <div class="left fauto df fd_c ai_c jc_c">
        <h5>请拨打官方热线</h5>
        <h2>400-6710-880</h2>
        <h5>180-190-39339 (微信同号)</h5>
      </div>
      <div class="right fgrow df fd_c ai_c j_sb">
        <h5>关注微信</h5>
        <p>微信在线洽谈或留下信息</p>
        <p>我们将尽快联系您</p>
        <p><img class="QRCode" src="/img/erwm.jpg" alt="二维码" /></p>
      </div>
    </div>
    <!--  -->
    <div class="Download_popUp df jc_sb" v-if="Download_state">
      <div class="fgrow" @click="Download_state=false"></div>
      <div class="df fd_c jc_sb">
        <div class="fgrow" @click="Download_state=false"></div>
        <div class="content fauto df ai_c jc_sb">
         <div class="left">
          <p>感谢您选择云店掌，请先微信扫码关注云店掌公众号（右图）</p>
          <p>关注后，点击马上下载，将为您把云店掌安装文件下载到您的电脑</p>
          <p>下载完成后，请在电脑上，双击打开下载的文件，系统会自动安装</p>
          <p>有任何问题请咨询：400-6710-880<span style="padding-left:10px;">180-190-39339 (微信同号)</span></p>
          <p v-if="shebe_name!='computer'" style="color:#f00;">请在电脑端下载</p>
          <div  v-if="shebe_name=='computer'" class="df ai_c jc_sa" style="margin-top:20px;">
            <div class="an df ai_c jc_c" @click="Download_state=false;" style="background:#727272;">取消</div>
            <div class="an df ai_c jc_c" @click="Download_file()">马上下载</div>
          </div>
          <div  v-else class="df ai_c jc_c" style="margin-top:20px;">
            <div class="an df ai_c jc_c" @click="Download_state=false;" style="background:#727272;">取消</div>
          </div>
         </div>
         <div class="right">
          <p><img class="QRCode" src="/img/erwm.jpg" alt="二维码" /></p>
         </div>
        </div>
        <div class="fgrow" @click="Download_state=false"></div>
      </div>
      <div class="fgrow" @click="Download_state=false"></div>
    </div>

  </div>
</template>
  
<script>
var scrollTop = 0;
var region = require("@/config/d"); //地区
export default {
  data() {
    return {
      contactUs_state: false,
      nav_state: true,

      navr_state:false,

      //选择申请地区
      Select_Area_state: false,

      URL_name:'', //当前url 名称
      province: [],
      city: [],
      area: [],
      region_active1: 0,
      region_active2: 0,
      region_active3: 0,
      submit_data: {
        linkman: "官网客户",
        phone: "",
        source: "线上",
        type: "1",
        product: ["Saas系统"],
      },
      document_domain: "",
      // 
      shebe_name:'',//
      Download_state:false
      
      
    };
  },
  created() {
    this.URL_name =window.location.pathname;

    this.document_domain = document.domain;
    let RegionalData = JSON.stringify(region);
    RegionalData = JSON.parse(RegionalData);
    let province = [];
    province = RegionalData;
    this.province = province;
    let city = province[0].child;
    this.city = city;
    let area = city[0].child;
    this.area = area;

    this.get_shebe()
  },
  mounted() {
    this.$overallNews.$on("Update_index", (res) => {
      console.log(res);
      if (res.type == "contactUs_state") {
        let time = setInterval(() => {
          clearInterval(time);
          this.contactUs_state = res.state;
        }, 100);
      }
    });

    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    on_scrollTop(){
      window.scroll(0,100000000);
    },
    on_navli(url) {
      // document.documentElement.scrollTop = 0;
      window.scroll(0,0);
      this.URL_name = url;
      this.$router.push({ path: url });
    },
    on_navli_open(url) {
      window.open(url);
    },
    //选择地区
    on_choice_address() {
      let time = setInterval(() => {
        clearInterval(time);
        this.Select_Area_state = true;
      }, 100);
    },
    on_region_list1(index) {
      let province = this.province;
      this.region_active1 = index;
      this.region_active2 = 0;
      this.region_active3 = 0;

      let city = province[index].child;
      this.city = [];
      this.area = [];

      let time = setInterval(() => {
        clearInterval(time);
        this.city = city;
        let area = city[0].child;
        this.area = area;
      }, 100);
    },
    on_region_list2(index) {
      let city = this.city;
      this.region_active2 = index;
      this.region_active3 = 0;
      if (city[index].child) {
        let area = city[index].child;
        this.area = area;
      } else {
        this.area = [];
      }
    },
    on_region_list3(index) {
      this.region_active3 = index;
    },
    isPhone(phone) {
      if (!phone) {
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    //免费申请
    on_submit_apply() {
      let submit_data = this.submit_data;
      submit_data.province = this.province[this.region_active1].id;
      submit_data.city = this.city[this.region_active2].id;
      submit_data.district = this.area[this.region_active3].id;
      if (!submit_data.phone) {
        alert("请输入联系电话");
        return;
      }
      if (!this.isPhone(submit_data.phone)) {
        alert("联系人手机号格式错误");
        return;
      }
      var url = "https://1880880.com/pms-recommend/consult";
      this.$Hrequest("POST", url, "", submit_data).then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          this.submit_data.phone = "";
          alert("提交申请成功");
        } else {
          let data = res.data;
          if (data.error) {
            this.submit_data.phone = "";
            alert(data.error);
          } else {
            alert("请求：pms-recommend/consult出错");
          }
        }
      });
    },
    get_shebe(){
      var os = function () {
			var ua = navigator.userAgent,
				isWindowsPhone = /(?:Windows Phone)/.test(ua),
				isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
				isAndroid = /(?:Android)/.test(ua),
				isFireFox = /(?:Firefox)/.test(ua),
				isChrome = /(?:Chrome|CriOS)/.test(ua),
				isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
					/(?:Tablet)/.test(ua)),
				isPhone = /(?:iPhone)/.test(ua) && !isTablet,
				isPc = !isPhone && !isAndroid && !isSymbian;
			return {
				isTablet: isTablet,
				isPhone: isPhone,
				isAndroid: isAndroid,
				isPc: isPc
			};
		}();
		if (os.isAndroid || os.isPhone) {
			// 手机
      this.shebe_name = 'phone';
 
		} else if (os.isTablet) {
			// 平板
      this.shebe_name = 'Flat';

		} else if (os.isPc) {
			// 电脑
      this.shebe_name = 'computer';
		}
    console.log(this.shebe_name)
    },
    handleScroll(e) {
      // console.log('页面滚动',document.documentElement.scrollTop)
      // let scroll_top = document.documentElement.scrollTop;
      // if (scrollTop < scroll_top) {
      //   this.nav_state = false;
      // } else if (this.nav_state == false) {
      //   this.nav_state = true;
      // }
      // scrollTop = document.documentElement.scrollTop;
    },
    //下载文件
    Download_file(){
      this.Download_state=false;
      let ww_down1 = 'http://dl0.zhinengmeng.com/云店掌Saas收银系统安装文件.exe'; 
      let ww_down2 = 'http://dl.yolosoft.cn/云店掌Saas收银系统安装文件.exe';
      let ww_down3 = 'http://dl2.1880880.com/云店掌Saas收银系统安装文件.exe';
      let ww_down4 = 'http://dl3.1880880.com/云店掌Saas收银系统安装文件.exe'; 
      let ww_down5 = 'http://dl4.1880880.com/云店掌Saas收银系统安装文件.exe'; 
      let ww_down6 = 'http://dl0.1880880.com/云店掌Saas收银系统安装文件.exe'; 
      let items = [ww_down1,ww_down2,ww_down3,ww_down4,ww_down5,ww_down6];
      let item = items[Math.floor(Math.random()*items.length)];
      window.open(item);
    }
  },
  beforeDestroy() {
    this.$overallNews.$off("Update_index");

    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less">
@import "~@/assets/less/home.less";
</style>