<template>
  <div id="Mapp">
    <router-view />
    <!--  -->
    <div class="message" v-if="message_state" style="color:#000; background:#fff;box-shadow: 0 4px 12px rgba(0,0,0,.15);">
      {{message_text}}
    </div>
    <!--  -->
  </div>

</template>
<script>
export default {
  name: "App",
  data() {
    return {
      message_state: false,
      message_text: "",
    };
  },
  mounted() {
    this.$overallNews.$on("onMmessage", (res) => {
      console.log(res)
      if (!this.message_state) {
        this.message_state = true;
        this.message_text = res.text
        let time = setInterval((res) => {
          clearInterval(time);
          this.message_state = false;
        }, 2000);
      }
    });


    // setInterval(function () {
    //   if (process.env.NODE_ENV !== "development") {
    //     check();
    //   }
    // }, 2000);
    // var check = function () {
    //   function doCheck(a) {
    //     if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
    //       (function () {}["constructor"]("debugger")());
    //     } else {
    //       (function () {}["constructor"]("debugger")());
    //     }
    //     doCheck(++a);
    //   }
    //   try {
    //     doCheck(0);
    //   } catch (err) {}
    // };
  },
  beforeDestroy() {
    this.$overallNews.$off("onMmessage");
  },
};
</script>
<style lang="less">
@import "~@/assets/less/Hello.less";
</style>