<template>
  <div class="Phome">
    <!--  -->
    <div class="modular1" id="ancnav1">
      <div class="content  df ai_c jc_sb">
        <div class="left">
          <h2>云店掌打造基于Saas构架的全面PMS系统</h2>
          <p>专注酒店17年</p>
          <p>线上商城、周边商家，做能给商家赚钱的互联网系统</p>
          <p>量化业绩考核、维修管理，所有数据可追溯</p>
          <h5 class="df ai_c jc_c" @click="on_ConsultImmediately()">立即咨询</h5>
        </div>
        <div class="right">
          <img style="display:block;" src="/imgs/20221116175337.png" alt="" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="modularx2" style="user-select:none;">
      <img src="/imgs/1606924383350.jpg" alt="" />
      <div class="content">
        <div class="frame df ai_c jc_sb">
          <div>免费体验-云店掌SaaS酒店系统，基于国内先进的酒店PMS系统打造，简单快速，方便易用！</div>
          <div class="an" @click="on_scrollTop()">免费申请</div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="modular2">
      <ul class="df ai_c jc_sb">
        <li class="df fd_c ai_c animate__animated animate__bounceInUp">
          <div class="biaoti df ai_b jc_c">
            <h2>
              2006
            </h2>
            <small>年起</small>
          </div>
          <div class="describe">立足上海</div>
          <div class="title">专注酒店行业</div>
          <div class="describe">17年行业累积</div>
          <div class="describe">13个省市代理商</div>
          <div class="describe">300多个城市覆盖</div>
        </li>
        <li class="df fd_c ai_c animate__animated animate__bounceInUp">
          <div class="biaoti df ai_b jc_c">
            <h2>
              <countTo :startVal='100' :endVal='7000' :duration='10'></countTo>
            </h2>
            <small>+</small>
          </div>
          <div class="describe">酒店客户</div>
          <div class="title">服务经验</div>
          <div class="describe">上线酒店的大力支持和建议，这些宝贵意见成为云店掌产品的一部分，让我们一系列产品更懂客户所需</div>
        </li>
        <li class="df fd_c ai_c animate__animated animate__bounceInUp">
          <div class="biaoti df ai_b jc_c">
            <h2>
              <countTo :startVal='1' :endVal='10' :duration='10'></countTo>
            </h2>
            <small>大</small>
          </div>
          <div class="describe">场景支持</div>
          <div class="title">综合运营一体化</div>
          <div class="describe">Saas云构架，酒店、餐饮、民宿、门票、商铺、租赁、周边等多场景运营支持，为商家提供一体化解决方案</div>
        </li>
      </ul>
    </div>
    <!--  -->
    <div style="background-color:#000; background-size:cover;background-image:url('/imgs/exhibition/1440720.jpg');">
      <div class="modular3">

        <div class="content" id="ancnav2">
          <div class="title animate__animated animate__zoomIn">四大产品矩阵为酒店综合体提供一站式闭环解决方案</div>
          <ul class="list df jc_sb">
            <li>
              <div class="img">
                <img src="/imgs/00v58PICj5c6Pf8.jpg" alt="">
              </div>
              <h2 class="df ai_c">云店掌POS门店</h2>
              <h3> 前台收银服务(前台)</h3>
              <div class="describe df">
                <div>
                  <p> • 收银pos</p>
                  <p> • 移动pos</p>
                  <p> • 自助pos</p>
                  <p> • 百度智慧酒店</p>
                </div>
                <div>
                  <p> • 前台管理</p>
                  <p> • 聚合支付</p>
                  <p> • 会员连锁</p>
                </div>
              </div>
              <h3> 商户后台</h3>
              <div class="describe df">
                <div>
                  <p> • 基本档案管理</p>
                  <p> • 实时营业监控</p>
                  <p> • 报表对账稽核</p>
                  <p> • 分账体系报表</p>
                </div>
                <div>
                  <p> • 集团管理</p>
                  <p> • 活动策划</p>
                  <p> • 板块设置</p>
                </div>
              </div>
            </li>
            <li>
              <div class="img">
                <img src="/imgs/00v58PICj5c6Pf8.jpg" alt="">
              </div>
              <h2 class="df ai_c">入住通C端运营</h2>
              <h3>线上运营小程序</h3>
              <div class="describe df">
                <div style="width:50%;">
                  <p> • 扫一扫即达</p>
                  <p> • 管家服务</p>
                  <p> • 线上商城</p>
                  <p> • 周边商家</p>
                  <p> • 优惠券</p>
                  <p> • 场景消费券</p>
                  <p> • 商家核销券</p>
                  <p> • 视频号对接</p>
                </div>
                <div style="width:50%;">
                  <p> • 线上预订</p>
                  <p> • 线上连锁会员</p>
                  <p> • 充值积分</p>
                  <p> • 租赁服务</p>
                  <p> • 开票</p>
                  <p> • 自助餐券</p>
                </div>
              </div>
            </li>
            <li>
              <div class="img">
                <img src="/imgs/00v58PICj5c6Pf8.jpg" alt="">
              </div>
              <h2 class="df ai_c">周边异业联盟</h2>
              <h3>增值业务扩展收益</h3>
              <div class="describe">
                <p> • 本栋或周边足浴、KTV、鲜花店、水果店等</p>
                <p> • 根据店铺属性送达商品或设置上门核销券</p>
                <p> • 设置到账日，自动分账到账</p>
                <p> • 方便简单，解决周边异业联盟手工操作弊端</p>
              </div>
            </li>
            <li>
              <div class="img">
                <img src="/imgs/00v58PICj5c6Pf8.jpg" alt="">
              </div>
              <h2 class="df ai_c">集团分账体系</h2>
              <h3>会员分账</h3>
              <div class="describe">
                <p> • 会员分账</p>
                <p> • 详细分账报表</p>
                <p> • 集团账号管理</p>
                <p> • 充值赠送现金比</p>
                <p> • 自动计算</p>
                <p> • 报表生成</p>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="modular4" id="ancnav3">
      <div class="content">
        <div class="describe">
          酒店全业态/场景解决方案
        </div>
        <ul class="list df ai_c jc_sb fw_w">
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092613.png" alt="">
            </div>
            <p>客房</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092614.png" alt="">
            </div>
            <p>餐厅茶室</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092615.png" alt="">
            </div>
            <p>门票/游泳/健身</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092616.png" alt="">
            </div>
            <p>租赁</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092617.png" alt="">
            </div>
            <p>房车</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092618.png" alt="">
            </div>
            <p>周边商家</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092619.png" alt="">
            </div>
            <p>漂流、儿童乐园</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092620.png" alt="">
            </div>
            <p>热气球、滑翔伞</p>
          </li>
          <li class="df fd_c ai_c">
            <div class="img df ai_c jc_c">
              <img src="/imgs/20221116092621.png" alt="">
            </div>
            <p>烧烤、帐篷</p>
          </li>

        </ul>
      </div>
    </div>
    <!--  -->
    <div class="modular5">
      <div class="content">
        <div class="title">
          <h2>电脑/微信端协同工作    让酒店员工提高效率</h2>
          <p>所有操作可追溯，业绩考核可量化</p>
        </div>
        <div class="tabnav">
          <ul class="df ai_c jc_c">
            <li class="df ai_c jc_c" v-for="(item,index) in tabnav5" :key="index" @click="tabnav5_active=index" :class="[tabnav5_active==index?'active':'']">{{item.name}}</li>
          </ul>
        </div>
        <div class="tabli">
          <ul class="">
            <li class="df jc_sb" v-if="tabnav5_active==0">
              <div class="left">
                <h3 class="df ai_c">
                  <span class="shape"></span>
                  云店掌PMS及移动PMS
                </h3>
                <p>客人入住后，可以随时在任何地方与酒店工作人员使用移动PMS，续房、补交押金、退房、清洁房间等等业务操作，不需要到前台，便于前台更好服务新客人，提高新入住/入住中客人的满意度</p>
                <h5 class="df ai_c jc_c" @click="on_ConsultImmediately()">立即咨询</h5>
              </div>
              <div class="img">
                <img src="/imgs/img8.png" alt="">
              </div>
            </li>
            <li class="df jc_sb" v-if="tabnav5_active==1">
              <div class="left">
                <h3 class="df ai_c"><span class="shape"></span>入住通运营小程序</h3>
                <p>线上商城、线上订房、线上会员、优惠券、周边商家、管家服务等等优化酒店管理模式，为酒店扩展收益渠道，线上自动分账。同时送物接单评价，所有业务操作可追溯，让考核奖励可量化，极大地提高管理者对员工的公平性，留住好员工</p>
                <h5 class="df ai_c jc_c" @click="on_ConsultImmediately()">立即咨询</h5>
              </div>
              <div class="img">
                <img src="/imgs/img9.png" alt="">
              </div>
            </li>
            <li class="df jc_sb" v-if="tabnav5_active==2">
              <div class="left">
                <h3 class="df ai_c"><span class="shape"></span>东家助理小程序对账</h3>
                <p>财务、管理层随时随地打开微信核对账务、监管每日的详细流水，房价监督日志，业绩数据，让您在任何时刻都能轻松掌握运营情况</p>
                <h5 class="df ai_c jc_c" @click="on_ConsultImmediately()">立即咨询</h5>
              </div>
              <div class="img">
                <img src="/imgs/img10.png" alt="">
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <!--  -->
    <div class="AnchorPoint df ai_c jc_c">
      <div class="list">
        <ul>
          <li><a href="#ancnav1">体验-云店掌</a></li>
          <li><a href="#ancnav2">产品介绍</a></li>
          <li><a href="#ancnav3">产品方案</a></li>
          <li><a href="#ancnav4">使用客户</a></li>
        </ul>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="modular7" id="ancnav4">
      <div class="title">每天有7000+酒店在使用云店掌</div>
      <section class="content df ai_c jc_c" style="overflow: hidden;">
        <div class="df">
          <div class="fauto df fw_w five">
            <div class="img"><img src="/imgs/Layer 15.png"></div>
            <div class="img"><img src="/imgs/Layer 7.png"></div>
            <div class="img"><img src="/imgs/Layer 21.png"></div>
            <div class="img"><img src="/imgs/Layer 3.png"></div>
            <div class="img"><img src="/imgs/Layer 8.png"></div>
            <div class="img"><img src="/imgs/Layer 9.png"></div>
          </div>
          <div class="fauto df fw_w five">
            <div class="img"><img src="/imgs/Layer 8.png"></div>
            <div class="img"><img src="/imgs/Layer 38.png"></div>
            <div class="img"><img src="/imgs/Layer 11.png"></div>
            <div class="img"><img src="/imgs/Layer 36.png"></div>
            <div class="img"><img src="/imgs/Layer 39.png"></div>
            <div class="img"><img src="/imgs/Layer 9.png"></div>
          </div>
          <div class="fauto df fw_w five">
            <div class="img"><img src="/imgs/Layer 33.png"></div>
            <div class="img"><img src="/imgs/Layer 32.png"></div>
            <div class="img"><img src="/imgs/Layer 31.png"></div>
            <div class="img"><img src="/imgs/Layer 30.png"></div>
            <div class="img"><img src="/imgs/Layer 22.png"></div>
            <div class="img"><img src="/imgs/Layer 29.png"></div>
          </div>
          <div class="fauto df fw_w one">
            <div class="img"><img src="/imgs/Layer 15.png"></div>
            <div class="img"><img src="/imgs/Layer 5.png"></div>
            <div class="img"><img src="/imgs/Layer 3.png"></div>
            <div class="img"><img src="/imgs/Layer 4.png"></div>

            <div class="img"><img src="/imgs/Layer 26.png"></div>
            <div class="img"><img src="/imgs/Layer 7.png"></div>
            <div class="img"><img src="/imgs/Layer 8.png"></div>
            <div class="img"><img src="/imgs/Layer 9.png"></div>

            <div class="img"><img src="/imgs/Layer 10.png"></div>
            <div class="img"><img src="/imgs/Layer 11.png"></div>
            <div class="img"><img src="/imgs/Layer 15.png"></div>
            <div class="img"><img src="/imgs/Layer 20.png"></div>
          </div>
          <div class="fauto df fw_w one">
            <div class="img"><img src="/imgs/Layer 21.png"></div>
            <div class="img"><img src="/imgs/Layer 22.png"></div>
            <div class="img"><img src="/imgs/Layer 25.png"></div>
            <div class="img"><img src="/imgs/Layer 26.png"></div>

            <div class="img"><img src="/imgs/Layer 27.png"></div>
            <div class="img"><img src="/imgs/Layer 29.png"></div>
            <div class="img"><img src="/imgs/Layer 20.png"></div>
            <div class="img"><img src="/imgs/Layer 10.png"></div>

            <div class="img"><img src="/imgs/Layer 32.png"></div>
            <div class="img"><img src="/imgs/Layer 33.png"></div>
            <div class="img"><img src="/imgs/Layer 38.png"></div>
            <div class="img"><img src="/imgs/Layer 36.png"></div>
          </div>
          <div class="fauto df fw_w one">
            <div class="img"><img src="/imgs/Layer39.png"></div>
            <div class="img"><img src="/imgs/Layer38.png"></div>
            <div class="img"><img src="/imgs/Layer 15.png"></div>
            <div class="img"><img src="/imgs/Layer 4.png"></div>

            <div class="img"><img src="/imgs/Layer 7.png"></div>
            <div class="img"><img src="/imgs/Layer 39.png"></div>
            <div class="img"><img src="/imgs/Layer36.png"></div>
            <div class="img"><img src="/imgs/Layer 10.png"></div>

            <div class="img"><img src="/imgs/Layer 3.png"></div>
            <div class="img"><img src="/imgs/Layer 25.png"></div>
            <div class="img"><img src="/imgs/Layer 5.png"></div>
            <div class="img"><img src="/imgs/Layer 32.png"></div>
          </div>
          <div class="fauto df fd_c two">
            <div>
              <p>中国酒店旅游行业的</p>
              <p>数字互联网平台建设者和推动者</p>
            </div>
            <div class="df ai_c">
              <div class="img"><img src="/imgs/Layer 11.png"></div>
              <div class="img"><img src="/imgs/Layer 20.png"></div>
              <div class="img"><img src="/imgs/Layer 21.png"></div>
              <div class="img"><img src="/imgs/Layer 2.png"></div>
            </div>
          </div>
          <div class="fauto df fw_w three">
            <div class="img"><img src="/imgs/Layer 33.png"></div>
            <div class="img"><img src="/imgs/Layer 29.png"></div>
            <div class="img"><img src="/imgs/Layer 8.png"></div>
            <div class="img"><img src="/imgs/Layer 27.png"></div>
            <div class="img"><img src="/imgs/Layer 37.png"></div>
            <div class="img"><img src="/imgs/Layer 30.png"></div>
            <div class="img"><img src="/imgs/Layer35.png"></div>
            <div class="img"><img src="/imgs/Layer 1.png"></div>
            <div class="img"><img src="/imgs/Layer34.png"></div>
          </div>
          <div class="fauto four">
            <div class="img"><img src="/imgs/Layer 6.jpg"></div>
            <div class="df">
              <div class="img"><img src="/imgs/Layer37.png"></div>
              <div class="img"><img src="/imgs/Layer 31.png"></div>
            </div>
          </div>
          <div class="fauto df fw_w five">
            <div class="img"><img src="/imgs/Layer 26.png"></div>
            <div class="img"><img src="/imgs/Layer 7.png"></div>
            <div class="img"><img src="/imgs/Layer 36.png"></div>
            <div class="img"><img src="/imgs/Layer 38.png"></div>
            <div class="img"><img src="/imgs/Layer 22.png"></div>
            <div class="img"><img src="/imgs/Layer 9.png"></div>
          </div>
          <div class="fauto df fw_w three">
            <div class="img"><img src="/imgs/Layer 33.png"></div>
            <div class="img"><img src="/imgs/Layer 21.png"></div>
            <div class="img"><img src="/imgs/Layer 8.png"></div>
            <div class="img"><img src="/imgs/Layer 27.png"></div>
            <div class="img"><img src="/imgs/Layer 3.png"></div>
            <div class="img"><img src="/imgs/Layer 30.png"></div>
            <div class="img"><img src="/imgs/Layer 15.png"></div>
            <div class="img"><img src="/imgs/Layer 25.png"></div>
            <div class="img"><img src="/imgs/Layer 29.png"></div>
          </div>
          <div class="fauto df fw_w five">
            <div class="img"><img src="/imgs/Layer 5.png"></div>
            <div class="img"><img src="/imgs/Layer 10.png"></div>
            <div class="img"><img src="/imgs/Layer 3.png"></div>
            <div class="img"><img src="/imgs/Layer 33.png"></div>
            <div class="img"><img src="/imgs/Layer 32.png"></div>
            <div class="img"><img src="/imgs/Layer 21.png"></div>
          </div>
          <div class="fauto df fw_w three">
            <div class="img"><img src="/imgs/Layer 26.png"></div>
            <div class="img"><img src="/imgs/Layer 3.png"></div>
            <div class="img"><img src="/imgs/Layer 8.png"></div>
            <div class="img"><img src="/imgs/Layer 7.png"></div>
            <div class="img"><img src="/imgs/Layer 20.png"></div>
            <div class="img"><img src="/imgs/Layer 30.png"></div>
            <div class="img"><img src="/imgs/Layer 31.png"></div>
            <div class="img"><img src="/imgs/Layer 25.png"></div>
            <div class="img"><img src="/imgs/Layer 4.png"></div>
          </div>
          <div class="fauto df fw_w five">
            <div class="img"><img src="/imgs/Layer 5.png"></div>
            <div class="img"><img src="/imgs/Layer 10.png"></div>
            <div class="img"><img src="/imgs/Layer 11.png"></div>
            <div class="img"><img src="/imgs/Layer 15.png"></div>
            <div class="img"><img src="/imgs/Layer 22.png"></div>
            <div class="img"><img src="/imgs/Layer 21.png"></div>
          </div>
          <div class="fauto df fw_w three">
            <div class="img"><img src="/imgs/Layer 27.png"></div>
            <div class="img"><img src="/imgs/Layer 29.png"></div>
            <div class="img"><img src="/imgs/Layer 32.png"></div>
            <div class="img"><img src="/imgs/Layer 33.png"></div>
            <div class="img"><img src="/imgs/Layer 4.png"></div>
            <div class="img"><img src="/imgs/Layer 39.png"></div>
            <div class="img"><img src="/imgs/Layer 20.png"></div>
            <div class="img"><img src="/imgs/Layer 36.png"></div>
            <div class="img"><img src="/imgs/Layer 3.png"></div>
          </div>
          
        </div>
      </section>
    </div>
    <!--  -->
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  components: { countTo },
  data() {
    return {
      nav_state: true,
      tabnav5: [
        {
          name: "云店掌PMS及移动PMS",
        },
        {
          name: "入住通运营小程序",
        },
        {
          name: "东家助理小程序对账",
        },
      ],
      tabnav5_active: 0,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    on_scrollTop(){
      // document.documentElement.scrollTop = 100000000;

      window.scroll(0,100000000);
    },
    on_ConsultImmediately() {
      this.$overallNews.$emit("Update_index", {
        type: "contactUs_state",
        state: true,
      });
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less">
@import "~@/assets/less/Phome.less";
</style>