<template>
  <div class="Select_Region df fd_c jc_sb">
    <div class="fgrow"></div>
    <div class="fauto">
      <div class="title df ai_c jc_c">地区选择</div>
      <div class="content df jc_sb">
        <div class="list" style="border-right: 1px solid #ccc;">
          <ul >
            <li class="li df ai_c jc_c" v-for="(item,index) in province" @click="on_list1(index)" :class="[active1==index?'active':'']" :key="index">{{item.label}}</li>
          </ul>
        </div>
        <div class="list" style="border-right: 1px solid #ccc;">
          <ul>
            <li class="li df ai_c jc_c" v-for="(item,index) in city" @click="on_list2(index)" :class="[active2==index?'active':'']" :key="index">{{item.label}}</li>
          </ul>
        </div>
        <div class="list">
          <ul>
            <li class="li df ai_c jc_c" v-for="(item,index) in area" @click="on_list3(index)" :class="[active3==index?'active':'']" :key="index">{{item.label}}</li>
          </ul>
        </div>
      </div>
      <div class="anGroup df ai_c jc_sa">
        <div class="fgrow df ai_c jc_c" @click="on_close()" style="border-right: 1px solid #e6e6e6;">取消</div>
        <div class="fgrow df ai_c jc_c" @click="on_define()">确定</div>
      </div>
    </div>
  </div>
</template>

<script>

var region = require("@/config/d"); //地区
export default {
  data() {
    return {
      province: [],
      city: [],
      area: [],
      active1: 0,
      active2: 0,
      active3: 0,
    };
  },
  created() {
    let RegionalData = JSON.stringify(region);
    RegionalData = JSON.parse(RegionalData);
    let province = [];
    province = RegionalData;
    this.province = province;
    let city = province[0].child;
    this.city = city;
    let area = city[0].child;
    this.area = area;
  },
  methods: {
    on_list1(index) {
      let province = this.province;
      this.active1 = index;
      this.active2 = 0;
      this.active3 = 0;

      let city = province[index].child;
      this.city = city;
      let area = city[0].child;
      this.area = area;
    },
    on_list2(index) {
      let city = this.city;
      this.active2 = index;
      this.active3 = 0;
      if(city[index].child){
        let area = city[index].child;
        this.area = area;
      }else{
        this.area = []
      }
    },
    on_list3(index) {
      this.active3 = index;
    },
    
    on_close() {
      var state = false;
      this.$emit("onclick", { state });
    },
    on_define(){
      if(this.area.length==0){
        return
      }
      var state = false;
      let province = this.province[this.active1]
      delete province.child
      let city = this.city[this.active2]
      delete city.child
      let area = this.area[this.active3]
      this.$emit("onclick", { state,province,city,area});
    }
  },
};
</script>

<style lang="less">
.Select_Region {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #00000050;
  .title {
    height: 50px;
    background: #fff;
    font-size: 18px;
  }
  .content {
    height: 280px;
    background: #ececec;
    border-top: 1px solid #ccc;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    .list {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
      width: 33.33%;
      overflow-y: scroll;
      background:#fff;
      touch-action: pan-y;
      -webkit-overflow-scrolling: touch;
      ul {
        margin: 0;
        padding: 0;
        background:#fff;
        padding:20px 10px;
      }
      .li {
        height: 40px;
        opacity: 0.3;
        font-size: 14px;
      }
      .active {
        color: #222;
        font-size: 16px;
        opacity: 1;
      }
    }
  }
  .anGroup {
    height: 50px;
    background: #fff;
    border-top: 1px solid #ddd;
    font-size: 16px;
  }
  
}
</style>